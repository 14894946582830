import React, { useEffect, useState } from 'react';

import { Alert, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';

import moment from 'moment';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ObtenerAsentamientos, ObtenerCodigosPostales } from '../../actions/catalogos/asentamientoActions';
import { ObtenerDistritosJudiciales } from '../../actions/catalogos/distritoJudicialActions';
import { ObtenerEstados } from '../../actions/catalogos/estadoActions';
import { ObtenerJuzgadosDistrito } from '../../actions/catalogos/juzgadoActions';
import { ObtenerMunicipios } from '../../actions/catalogos/municipioActions';
import { ObtenerTiposDiligencia } from '../../actions/catalogos/tipoDiligenciaActions';
import { ObtenerTipoDomicilios } from '../../actions/catalogos/tipoDomicilioActions';

import { AgregarBoleta } from '../../actions/boleta/boletaActions';

import { ObtenerExpedienteBoletas } from '../../actions/expediente/expedienteVirtualActions';
import { ObtenerTipoParte } from '../../actions/catalogos/tipoParteActions';

export const RealizarBoletaScreen = () => {

    const [rows, setRows] = useState( [] );
    const [rowsPersonas, setRowsPersonas] = useState( [] );

    const [open, setOpen] = useState( false );
    const [openAgregar, setOpenAgregar] = useState( false );
    const [openConfirmacionAgregarBoleta, setOpenConfirmacionAgregarBoleta] = useState( false );
   
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 5 );
    const [pagePersonas, rowsPerPagePersonas, handleChangePagePersonas, handleChangeRowsPerPagePersonas] = usePages( 5 );

    const [loading, setLoading] = useState( false );
    const [loadingAgregarPersona, setLoadingAgregarPersona] = useState( false );
    const [loadingAgregarBoleta, setLoadingAgregarBoleta] = useState( false );

    const [distritoJudicial, setDistritoJudicial ] = useState( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray ] = useState( [] );

    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadoArray, setJuzgadoArray] = useState( [ ] );

    const [tipoDiligencia, setTipoDiligencia] = useState(0);
    const [tipoDiligenciaArray, setTipoDiligenciaArray] = useState( [ ] ) ;

    const [numeroExpediente, setNumeroExpediente] = useState( '' );

    const [nombre, setNombre] = useState( '' );
    const [apellido1, setApellido1] = useState( '' );
    const [apellido2, setApellido2] = useState( '' );

    const [tipoParte, setTipoParte] = useState( 0 );
    const [tipoParteArray, setTipoParteArray] = useState( [] );

    const [estado, setEstado] = useState( 0 );
    const [estadoArray, setEstadoArray] = useState( [] );

    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [] );

    const [codigoPostal, setCodigoPostal] = useState( '0' );
    const [codigoPostalArray, setCodigoPostalArray] = useState( [] );

    const [asentamiento, setAsentamiento] = useState( 0 );
    const [asentamientoArray, setAsentamientoArray] = useState( [] );

    const [tipoDomicilio, setTipoDomicilio] = useState( 0 );
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState( [] );

    const [calle, setCalle] = useState( '' );
    const [numero, setNumero] = useState( '' );
    const [referencia, setReferencia] = useState( '' );
    const [observaciones, setObservaciones] = useState( '' );

    const [errores, setErrores] = useState( {} );
    
    const [alert, setAlert] = useState( {} );

    const [idReferencia, setIdReferencia] = useState( 0 );
    const [expediente, setExpediente] = useState( {} );

    const [filtroAplicado, setFiltroAplicado] = useState( false );    
    
    const validateFields = () => {
        
        let valid = true;
        let errores = {};

        if ( nombre === '' ) {
            valid = false
            errores.nombre = 'Escribe el nombre'
        }

        if ( apellido1 === '' ) {
            valid = false
            errores.apellido1 = 'Escribe el apellido 1'
        }

        if ( tipoParte === 0 ) {
            valid = false
            errores.tipoParte = 'Seleccione el tipo de parte'
        }     

        if ( estado === 0 ) {
            valid = false
            errores.estado = 'Seleccione un estado'
        }

        if ( municipio === 0 ) {
            valid = false
            errores.municipio = 'Seleccione un municipio'
        }

        if ( codigoPostal === '0' ) {
            valid = false
            errores.codigoPostal = 'Seleccione un código Postal'
        }

        if ( tipoDomicilio === 0 ) {
            valid = false
            errores.tipoDomicilio = 'Seleccione un tipo de domicilio'
        }
        if ( asentamiento === 0 ) {
            valid = false
            errores.asentamiento = 'Seleccione un asentamiento'
        }

        if ( calle === '' ) {
            valid = false
            errores.calle = 'Escribir la calle o vialidad'
        }        

        setErrores( errores );

        return valid;
    }

     const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setDistritoJudicial( 0 );
        setJuzgado( 0 );        
        setNumeroExpediente( '' );
        setExpediente( {} );

        resetPage();
        setRows( [] );
    }

    const handleClose = () => {

        setNombre( '' );
        setApellido1( '' );
        setApellido2( '' );
        
        setReferencia( '' );
        setNumero( '' );
        setCalle( '' );
        setTipoDomicilio( 0 );

        setAsentamiento( 0 );
        setCodigoPostal( '0' );        
        setMunicipio( 0 );
        setEstado( 0 );

        setErrores( {} );
        
        setOpen( false );
        setOpenAgregar( false );
        setAlert( { open:false });
    }
    
    const handleLimpiar = () => {

        setDistritoJudicial(0);
        setJuzgado(0);
        setTipoDiligencia(0);

        setNumeroExpediente('');
        setExpediente( {} );
        
        setRows([]);
        setRowsPersonas([]);       
        setObservaciones('');  

        setFiltroAplicado( false ); 

    }    

    const handleOpen = () => {
        setOpen ( true );
    };  

    const handleClicEliminarParte = ( Id ) => {

        handleOpen();
        setIdReferencia( Id );

    }    

    const handleEliminarPersona = () => {

        const newArray = rowsPersonas.filter( ( elem ) => elem.id !== idReferencia );

        setRowsPersonas ( newArray );        

        handleClose();
    }    

    const handleGuardarPersona = () => {

        const valid = validateFields();

        if( !valid ){
            return false;
        }       

        const searchAsentamiento = asentamientoArray.find( ( elem ) => elem.idAsentamiento === asentamiento );        
        const searchEstado = estadoArray.find( ( elem ) => elem.idEstado === estado );
        const searchMunicipio = municipioArray.find( ( elem ) => elem.idMunicipio === municipio ); 
        const searchTipoParte = tipoParteArray.find( ( elem ) => elem.idTipoParte === tipoParte ); 

        setLoadingAgregarPersona( true );
        
        setTimeout(() => {
            
            setRowsPersonas([ 
                ...rowsPersonas,
                { 
                    id: rowsPersonas.length + 1, 
                    nombre: nombre, 
                    apellido1: apellido1, 
                    apellido2: apellido2, 
                    domicilio: 'C. ' + calle + ', No. ' + numero +', Col. ' + ( searchAsentamiento ? searchAsentamiento.descripcion : '' ) + ', ' + ( searchMunicipio ? searchMunicipio.descripcion : '' ) + ', ' + ( searchEstado ? searchEstado.descripcion : '' ),
                    idAsentamiento: asentamiento,
                    idTipoDomicilio: tipoDomicilio,
                    numero: numero,
                    referencia: referencia,
                    calleVialidad: calle,
                    idTipoParte: tipoParte,
                    tipoParte: searchTipoParte,
                }, 
            ]);   
    
            handleClose();
            setLoadingAgregarPersona( false );

        }, 500);
        
    }

    const handleAgregarBoleta = async () => {

        const params = {
            idJuzgado: juzgado,             
            idDistritoJudicial: distritoJudicial,
            numeroExpediente: numeroExpediente,
            observaciones: observaciones,
            idTipoDiligencia: tipoDiligencia,
            personas: rowsPersonas,
            acuerdos: rows.filter( ( elem ) => elem.selected ),
        };

        setLoadingAgregarBoleta( true );

        await AgregarBoleta( params ).then( response => {

            if( response ){

                if( response.status === 200 ){                    
                
                    const { success, message } = response.data;

                    if( success ){
                        setTimeout(() => {

                            setOpenConfirmacionAgregarBoleta( false );
                            setLoadingAgregarBoleta( false );
                            handleLimpiar();
                        
                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'success'
                            } ); 
                            
                        }, 1000);
                    }
                    else{
                        setTimeout(() => {

                            setOpenConfirmacionAgregarBoleta( false );
                            setLoadingAgregarBoleta( false );
                        
                            setAlert( {
                                open: true,
                                msg: message,
                                type: 'error'
                            } ); 
                            
                        }, 1000);
                    }
                    
                    
                }
                else{
                    setTimeout(() => {

                        setLoadingAgregarBoleta( false );
                        
                    }, 1000);
                }
            }

        });

    }

    const handleChangeSelected = ( checked, idAcuerdo ) => {

        const newArray = rows.map( ( elem ) => {
            if( elem.idAcuerdo === idAcuerdo ){
                elem.selected = checked;
            }
            else{
                elem.selected = false;
            }
            return elem;
        });

        setRows( newArray );
    }

    const handleObtenerExpediente = async () => {

        const validExpediente = numeroExpediente.includes('/');

        if( validExpediente ){

            const consecutivo = ( numeroExpediente.substring(0, numeroExpediente.indexOf('/')) );
            const anio = ( numeroExpediente.substring(numeroExpediente.indexOf('/') + 1) );

            if( !isNaN( consecutivo ) && !isNaN( anio )  ){

                setLoading( true );
                setExpediente( {} );
                setRows( [] );
              
                await ObtenerExpedienteBoletas({ numeroExpediente, idJuzgado: juzgado }).then( response => {

                    if( response ){

                        if( response.status === 200 ){

                            const { success } = response.data;

                            if( success ){

                                setTimeout(() => {
                                
                                    setFiltroAplicado( true ); 
                                        
                                    setExpediente( { ...response.data, Expediente: numeroExpediente } ?? {} );
                                    
                                    setRows( response?.data?.acuerdos.map(( elem ) => { return { ...elem, fechaAcuerdo: elem.fecha, selected: false, } }) ?? [] );
                                    
                                    setLoading( false );

                                }, 500);
                                
                            }
                            else{
                                setTimeout(() => {

                                    setLoading( false );
                                    setExpediente( {} );
                                    setRows( [] );

                                }, 500);
                            }

                        }
                        else{
                            setTimeout(() => {

                                setLoading( false );
                                setExpediente( {} );
                                setRows( [] );

                            }, 500);
                        }

                    }

                })           

            }

        }

    }

    useEffect(() => {
        
      async function Obtener(){

          await ObtenerDistritosJudiciales().then( response => {

              if ( response ){

                if( response.status === 200 ){
                    setDistritoJudicialArray( response.data );
                }

              }
          })

      }
      Obtener();

    }, [ ]);

    useEffect(() => {      
    
        async function Obtener(){

            if( distritoJudicial !== 0 ){

                await ObtenerJuzgadosDistrito( distritoJudicial ).then( response => {

                    if( response ){
    
                        if( response.data ){
                            setJuzgadoArray( response.data );

                        }
    
                    }    
    
                });

            }
        }     
        
        Obtener();

    }, [ distritoJudicial ])

    useEffect(() => {      

        async function Obtener(){

            await ObtenerTiposDiligencia().then( response => {

                if( response ){

                    if( response.data ){
                        setTipoDiligenciaArray( response.data );
                    }

                }


            });

        }     
        
        Obtener();

    }, [ ])   

    useEffect(() => {

        async function Obtener() {

            await ObtenerEstados().then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setEstadoArray( response.data );
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener() {
            
            await ObtenerTipoParte().then( response => {

                if( response ){

                    if ( response.status === 200 ) {

                        if ( response.data ) {
                            setTipoParteArray( response.data );
                        }

                    }

                }

            });
        }

        Obtener();

    }, [ ])

    useEffect(() => {

        async function Obtener() {

            setMunicipio( 0 );
            
            await ObtenerMunicipios( estado ).then( response => {

                if( response ){

                    if ( response.status === 200 ) {

                        if ( response.data ) {
                            setMunicipioArray( response.data );
                        }

                    }

                }

            });
        }

        Obtener();

    }, [ estado ])

    useEffect(() => {

        async function Obtener() { 

            setCodigoPostal( '0' )
           
            await ObtenerCodigosPostales( municipio ).then( response => {

                if( response ){

                    if ( response.status === 200 ) {
                    
                        if ( response.data ) {
                            setCodigoPostalArray( response.data );
                        }

                    }

                }               

            });
        }

        Obtener();

    }, [ municipio ])

    useEffect(() => {

        async function Obtener() {

            setAsentamiento( 0 ) 

            const param = {
                codigoPostal: codigoPostal,
                idMunicipio: municipio,
            };

            await ObtenerAsentamientos(param).then(response => {

                if( response ){
             
                    if ( response.status === 200 ) {

                        if ( response.data ) {
                            setAsentamientoArray( response.data );
                        }

                    }

                }

            });
        }

        Obtener();

    }, [ municipio, codigoPostal ])

    useEffect(() => {

        async function Obtener() {

            setTipoDomicilio( 0 );
            
            await ObtenerTipoDomicilios().then(response => {

                if( response ){

                    if (response.status === 200) {
                        
                        if (response.data) {
                            setTipoDomicilioArray(response.data);
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])
   
    return (
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant= "filled" severity={ alert.type } sx={{ width: '100%' }}  >
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }
            
            <Grid container spacing={3} sx={{ mt: 1 }}>

               <Grid item md={6} xs={12} >   

                    <FormControl fullWidth>

                        <InputLabel id='select-distritoJudicial-label'>Distrito judicial</InputLabel>

                        <Select
                            labelId='select-distritoJudicial-label'
                            id='select-distritoJudicial'                                            
                            label='Distrito judicial'
                            name='distritoJudicial'                              
                            value = { distritoJudicial }                    
                            onChange={ (e) => { setDistritoJudicial(e.target.value); setJuzgado( 0 ); setNumeroExpediente( '' ); resetPage(); setExpediente( {} ); setRows( [] ); } }
                            disabled={ filtroAplicado }
                        >
                            <MenuItem value={0}>Seleccione una opción</MenuItem>
                            {
                                distritoJudicialArray
                                .filter( ( elem ) => elem.idDistritoJudicial === 1 || elem.idDistritoJudicial === 6 )
                                .map((elem)=> (
                                   <MenuItem key={elem.idDistritoJudicial} value={ elem.idDistritoJudicial }>{ elem.descripcion} </MenuItem>
                                ) )

                            }
                            
                        </Select>               

                    </FormControl>

                </Grid> 

                <Grid item md={6} xs={12} >   

                    <FormControl fullWidth>

                        <InputLabel id='select-juzgado-label'>Juzgado</InputLabel>

                        <Select
                            labelId='select-juzgado-label'
                            id='select-juzgado'                                            
                            label='Juzgado'
                            name='juzgado'
                            value={ juzgado }                                           
                            onChange={ ( e ) => { setJuzgado( e.target.value ); setNumeroExpediente( '' ); setExpediente( {} ); resetPage(); setRows( [] ); } }
                            disabled= { distritoJudicial === 0 || filtroAplicado }
                        >
                            <MenuItem value={0}>Seleccione una opción</MenuItem>                            
                            {
                                juzgadoArray
                                .filter( ( elem ) => elem.boletas === true )
                                .map((elem)=> (
                                    <MenuItem key={elem.idJuzgado} value={ elem.idJuzgado }>{ elem.descripcion} </MenuItem>
                                ))
                            }                                            
                        </Select>               

                    </FormControl>

                </Grid>  

                <Grid item md={5} xs={12} >   

                    <FormControl fullWidth>

                        <InputLabel id='select-tipoDiligencia-label'>Tipo de diligencia</InputLabel>

                        <Select
                            labelId='select-tipoDiligencia-label'
                            id='select-tipoDiligencia'                                            
                            label='Tipo de diligencia'
                            name='tipoDiligencia'
                            value={ tipoDiligencia }                                           
                            onChange={ ( e ) => { setTipoDiligencia( e.target.value ) } }
                        >
                            <MenuItem value={0}>Seleccione una opción</MenuItem>
                            {
                                tipoDiligenciaArray.map((elem)=>(
                                    <MenuItem key={elem.idTipoDiligencia} value={ elem.idTipoDiligencia }>{ elem.descripcion }</MenuItem>
                                ))
                            }
                            
                        </Select>               

                    </FormControl>

                </Grid>  

                <Grid item md={5} xs={12} >   

                    <TextField
                        name='numeroExpediente'
                        label='Número de expediente'                        
                        value={ numeroExpediente }
                        onChange={ (e) => setNumeroExpediente( e.target.value ) }
                        autoComplete='off'
                        fullWidth
                        disabled={ juzgado === 0 || filtroAplicado}
                    />

                </Grid>  

                <Grid item md={2} xs={12} >   

                    <LoadingButton
                        variant='contained'
                        fullWidth
                        startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                        onClick={ () => { filtroAplicado ? handleClearFiltro() : handleObtenerExpediente() } }
                        disabled={ juzgado === 0 || numeroExpediente === '' }
                        loading={ loading  }
                        color={ filtroAplicado ? 'inherit' : 'primary' }
                    >
                        { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                    </LoadingButton>

                </Grid>  

                <Grid item md={4} xs={12}>
                    <Typography variant='body2'>
                        <strong>Número de folio: </strong> <br />
                        { expediente?.folio }
                    </Typography>
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography variant='body2'>
                        <strong>Número de expediente: </strong> <br />
                        { expediente?.Expediente }
                    </Typography>
                </Grid>

                <Grid item md={4} xs={12}>
                    <Typography variant='body2'>
                        <strong>Tipo de juicio: </strong> <br />
                        { expediente?.tipoJuicio }
                    </Typography>
                </Grid>

                <Grid item md={12} xs={12} sx={{ mt: 5 }}>
                    <Typography variant='h6'>
                        Seleccione los acuerdos a notificar
                    </Typography>

                    <Divider sx={{ pb: 2 }} />
                </Grid>

                <Grid item md={12} xs={12} >

                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'1%' }}>  </TableCell>
                                    <TableCell style={{ width:'15%' }}> FECHA </TableCell>
                                    <TableCell style={{ width:'25%' }}> TIPO DE ACUERDO </TableCell>
                                    <TableCell style={{ width:'25%' }}> ACUERDO </TableCell>
                                    <TableCell style={{ width:'5%', textAlign: 'center' }}> SELECCIONAR </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    ( rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map( ( row, i ) => (

                                        <TableRow key={ i } >
                                            
                                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}> { i + 1  } </TableCell>
                                            <TableCell> { row.fechaAcuerdo ? moment( row.fechaAcuerdo ).format('DD-MM-YYYY') : '' } </TableCell>
                                            <TableCell> { row.tipo } </TableCell>
                                            <TableCell> { row.nombre } </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>   

                                                <Checkbox checked={ row.selected } onChange={ (e) => handleChangeSelected(e.target.checked, row.idAcuerdo) } />
                                  
                                            </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron acuerdos'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>
                        
                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={10}
                                        count={rows.length}                                        
                                        rowsPerPage={rowsPerPage}
                                        page={page}                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>

                <Grid item md={12} xs={12} sx={{ mt: 5 }}>

                    <Grid container spacing={3}>

                        <Grid item md={9} sm={7} xs={12}>
                            <Typography variant='h6'>
                                Añadir personas a notificar
                            </Typography>
                        </Grid>

                        <Grid item md={3} sm={5} xs={12}>
                            <Button
                                variant='contained'
                                fullWidth
                                startIcon={ <AddIcon /> }
                                onClick={ () => { setOpenAgregar( true ); } }
                                disabled={ rowsPersonas.length !== 0 }
                            >
                                Agregar persona
                            </Button>
                        </Grid>

                    </Grid>

                    <Divider sx={{ pb: 2 }} />

                </Grid>

                <Grid item md={12} xs={12} >

                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'15%' }}> NOMBRE(S) </TableCell>
                                    <TableCell style={{ width:'15%' }}> APELLIDO 1 </TableCell>
                                    <TableCell style={{ width:'15%' }}> APELLIDO 2 </TableCell>
                                    <TableCell style={{ width:'15%' }}> TIPO DE PARTE </TableCell>
                                    <TableCell style={{ width:'25%' }}> DOMICILIO </TableCell>
                                    <TableCell style={{ width:'5%', textAlign: 'center' }}>  </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    ( rowsPerPagePersonas > 0
                                        ? rowsPersonas.slice(pagePersonas * rowsPerPagePersonas, pagePersonas * rowsPerPagePersonas + rowsPerPagePersonas)
                                        : rowsPersonas
                                    ).map( ( row ) => (

                                        <TableRow key={ row.id } >
                                            
                                            <TableCell> { row.nombre } </TableCell>
                                            <TableCell> { row.apellido1 } </TableCell>
                                            <TableCell> { row.apellido2 } </TableCell>
                                            <TableCell> { row?.tipoParte?.descripcion } </TableCell>
                                            <TableCell> { row.domicilio } </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>   

                                                <IconButton
                                                    color='primary'
                                                    onClick= { () => { handleClicEliminarParte( row.id ); }}                                                     
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                  
                                            </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rowsPersonas.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                Añade una persona a notificar
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>
                        
                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={10}
                                        count={rowsPersonas.length}                                        
                                        rowsPerPage={rowsPerPagePersonas}
                                        page={pagePersonas}                                                                                
                                        onPageChange={ handleChangePagePersonas }
                                        onRowsPerPageChange={ handleChangeRowsPerPagePersonas }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>

                <Grid item md={12} xs={12} >

                    <TextField
                        multiline
                        rows={5}
                        fullWidth
                        label='Observaciones'
                        name='observaciones'
                        variant='outlined'
                        value={ observaciones }
                        onChange={ (e) => setObservaciones( e.target.value ) }
                        inputProps={{ maxLength: 500 }}
                        
                    />

                    <FormHelperText variant='standard' >Máximo { 500 - observaciones.length } caracteres</FormHelperText>

                </Grid>

                <Grid item md={12} xs={12} >

                    <Grid container spacing={3} sx={{ display: { sm: 'flex', xs: 'block' } }} flexDirection='column' alignContent='center'>

                        <Grid item sm={6} xs={12}>
                            
                            <Button
                                variant='contained'
                                startIcon={ <SaveIcon /> }
                                fullWidth
                                onClick={ () => { setOpenConfirmacionAgregarBoleta( true ) } }
                                disabled={ distritoJudicial === 0 || juzgado === 0 || tipoDiligencia === 0 || numeroExpediente === '' || rows.filter( (elem) => elem.selected ).length === 0 || rowsPersonas.length === 0 }
                            >
                                Guardar notificación
                            </Button>

                        </Grid>

                    </Grid>

                </Grid>

            </Grid>

            <Dialog
                open={open}
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea quitar el registro seleccionada?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpen( false ) } }>Cancelar</Button>
                    <Button variant='contained' color='primary' onClick={ handleEliminarPersona } >
                        Aceptar
                    </Button>
                </DialogActions>

            </Dialog>

            <Dialog
                open={openAgregar}
                onClose={ () => { } }
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                    Agregar persona
                </DialogTitle>
               
                <DialogContent>
                                    
                    <Grid container spacing={3}>

                        <Grid item md={12} xs={12} >

                            <Card>

                                <CardHeader 
                                    sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                    subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Datos de la persona </Typography> }
                                />
                                
                                <Divider />

                                <CardContent> 

                                    <Grid container spacing={3}>

                                        <Grid item md={12} xs={12}>

                                            <TextField 
                                                name='nombres'
                                                label='Nombre(s)'
                                                autoComplete='off'  
                                                fullWidth
                                                value={ nombre }
                                                onChange={ (e) => setNombre( e.target.value ) }
                                            />

                                            {
                                                errores.nombre && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.nombre } </Alert>)                          
                                            }

                                        </Grid>                                            

                                        <Grid item md={6} xs={12}>

                                            <TextField 
                                                name='apellido1'
                                                label='Apellido 1'
                                                autoComplete='off'  
                                                fullWidth
                                                value={ apellido1 }
                                                onChange={ (e) => setApellido1( e.target.value ) }
                                            />

                                            {
                                                errores.apellido1 && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.apellido1 } </Alert>)                          
                                            }

                                        </Grid>

                                        <Grid item md={6} xs={12}>

                                            <TextField 
                                                name='apellido2'
                                                label='Apellido 2'
                                                autoComplete='off'  
                                                fullWidth
                                                value={ apellido2 }
                                                onChange={ (e) => setApellido2( e.target.value ) }
                                            />

                                        </Grid>

                                        <Grid item md={12} xs={12} >   

                                            <FormControl fullWidth>

                                                <InputLabel id="select-tipoParte-label">Tipo de parte</InputLabel>

                                                <Select
                                                    labelId="select-tipoParte-label"
                                                    id="select-tipoParte"                                            
                                                    label="Tipo de parte"
                                                    name="tipoParte"
                                                    value={ tipoParte }
                                                    onChange={ (e) => setTipoParte( e.target.value ) }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        tipoParteArray.map( ( element ) => (
                                                            <MenuItem key={ element.idTipoParte } value={ element.idTipoParte }> { element.descripcion } </MenuItem>
                                                        ))
                                                    }

                                                </Select>

                                            </FormControl>

                                            {
                                                errores.tipoParte
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.tipoParte }
                                                </Alert>                          
                                            }

                                        </Grid>  

                                    </Grid>

                                </CardContent>
                            
                            </Card>

                        </Grid>

                        <Grid item md={12} xs={12} >

                            <Card>

                                <CardHeader 
                                    sx={{ backgroundColor: (theme) => theme.palette.primary.main }} 
                                    subheader={ <Typography sx={{ fontWeight: '400', color: 'white' }}> Domicilio </Typography> }
                                />
                                
                                <Divider />

                                <CardContent> 

                                    <Grid container spacing={3}>

                                        <Grid item md={6} xs={12} >   

                                            <FormControl fullWidth>

                                                <InputLabel id="select-estado-label">Estado</InputLabel>

                                                <Select
                                                    labelId="select-estado-label"
                                                    id="select-estado"                                            
                                                    label="estado"
                                                    name="estado"
                                                    value={ estado }
                                                    onChange={ (e) => setEstado( e.target.value ) }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        estadoArray.map( ( element ) => (
                                                            <MenuItem key={ element.idEstado } value={ element.idEstado }> { element.descripcion } </MenuItem>
                                                        ))
                                                    }

                                                </Select>

                                            </FormControl>

                                            {
                                                errores.estado
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.estado }
                                                </Alert>                          
                                            }
            
                                        </Grid>  

                                        <Grid item md={6} xs={12} >   

                                            <FormControl fullWidth>

                                                <InputLabel id="select-municipio-label">Municipio</InputLabel>

                                                <Select
                                                    labelId="select-municipio-label"
                                                    id="select-municipio"                                            
                                                    label="municipio"
                                                    name="municipio"
                                                    value={ municipio }
                                                    onChange={ (e) => setMunicipio( e.target.value ) }
                                                    disabled={ estado === 0 }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        municipioArray.map( ( element ) => (
                                                            <MenuItem key={ element.idMunicipio } value={ element.idMunicipio } > { element.descripcion } </MenuItem>
                                                        ))
                                                    }
                                                    
                                                </Select>

                                            </FormControl>

                                            {
                                                errores.municipio
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.municipio }
                                                </Alert>                          
                                            }
                                                    
                                        </Grid>  

                                        <Grid item md={6} xs={12} >   

                                            <FormControl fullWidth>

                                                <InputLabel id="select-codigoPostal-label">Código Postal</InputLabel>

                                                <Select
                                                    labelId="select-codigoPostal-label"
                                                    id="select-codigoPostal"                                            
                                                    label="codigoPostal"
                                                    name="codigoPostal"
                                                    value={ codigoPostal }
                                                    onChange={ (e) => setCodigoPostal( e.target.value ) }
                                                    disabled={ municipio === 0 }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        codigoPostalArray.map( ( element ) => (
                                                            <MenuItem key={ element.codigoPostal } value={ element.codigoPostal } > { element.codigoPostal } </MenuItem>
                                                        ))
                                                    }
                                                    
                                                </Select>

                                            </FormControl>

                                            {
                                                errores.codigoPostal
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.codigoPostal }
                                                </Alert>                          
                                            }
                                                
                                        </Grid>  

                                        <Grid item md={6} xs={12} >   

                                            <FormControl fullWidth>

                                                <InputLabel id="select-asentamiento-label">Asentamiento</InputLabel>

                                                <Select
                                                    labelId="select-asentamiento-label"
                                                    id="select-asentamiento"                                            
                                                    label="asentamiento"
                                                    name="asentamiento"
                                                    value={ asentamiento }
                                                    onChange={ (e) => setAsentamiento( e.target.value ) }
                                                    disabled={ codigoPostal === 0 }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        asentamientoArray.map( ( element ) => (
                                                            <MenuItem key={ element.idAsentamiento } value={ element.idAsentamiento } > { element.descripcion } </MenuItem>
                                                        ))
                                                    }
                                                    
                                                </Select>

                                            </FormControl>

                                            {
                                                errores.asentamiento
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.asentamiento }
                                                </Alert>                          
                                            }
                                                
                                        </Grid>  

                                        <Grid item md={4} xs={12} >   

                                            <FormControl fullWidth>

                                                <InputLabel id="select-tipoDomicilio-label">Tipo de Domicilio</InputLabel>

                                                <Select
                                                    labelId="select-tipoDomicilio-label"
                                                    id="select-tipoDomicilio"                                            
                                                    label="Tipo de Domicilio"
                                                    name="tipoDomicilio"
                                                    value={ tipoDomicilio }
                                                    onChange={ (e) => setTipoDomicilio( e.target.value ) }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        tipoDomicilioArray.map( ( element ) => (
                                                            <MenuItem key={ element.idTipoDomicilio } value={ element.idTipoDomicilio } > { element.descripcion } </MenuItem>
                                                        ))
                                                    }
                                                    
                                                </Select>

                                            </FormControl>

                                            {
                                                errores.tipoDomicilio
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.tipoDomicilio }
                                                </Alert>                          
                                            }
                                                
                                        </Grid>  

                                        <Grid item md={4} xs={12} >

                                            <TextField
                                                fullWidth
                                                label="Calle o Vialidad"
                                                name="calleVialidad"
                                                variant="outlined"       
                                                autoComplete='off'                 
                                                value={ calle }
                                                onChange={ (e) => setCalle( e.target.value ) }
                                            />
                                            
                                            {
                                                errores.calle
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.calle }
                                                </Alert>
                                            
                                            }
                                        </Grid>

                                        <Grid item md={4} xs={12} >

                                            <TextField
                                                fullWidth
                                                label="Número Int./ext."
                                                name="numero"
                                                variant="outlined"
                                                autoComplete='off'
                                                type='number'
                                                value={ numero }
                                                onChange={ (e) => setNumero( e.target.value ) }
                                            />
                                        
                                            {
                                                errores.numero
                                                &&                          
                                                <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                    { errores.numero }
                                                </Alert>
                                            
                                            }
                                        </Grid>


                                        <Grid item md={12} xs={12} >

                                            <TextField
                                                multiline
                                                rows={5}
                                                fullWidth
                                                label="Referencia"
                                                name="referencia"
                                                variant="outlined"     
                                                autoComplete='off'                   
                                                value={ referencia }
                                                onChange={ (e) => setReferencia( e.target.value ) }
                                            />
                                        </Grid>

                                    </Grid>

                                </CardContent>
                            
                            </Card>

                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ handleClose }>Cerrar</Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleGuardarPersona } startIcon={ <AddIcon /> } loading={ loadingAgregarPersona } >
                        Agregar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

            <Dialog
                open={openConfirmacionAgregarBoleta}
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea guardar la boleta de notificación?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacionAgregarBoleta( false ) } }>Cancelar</Button>
                    <LoadingButton variant='contained' color='primary' onClick={ handleAgregarBoleta } loading={ loadingAgregarBoleta }>
                        Aceptar
                    </LoadingButton>
                </DialogActions>

            </Dialog>

        </>
    )

}
