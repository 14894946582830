import React, { useEffect, useState } from 'react';

import { Alert, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import moment from 'moment';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { ActualizarPromocionJuzgado, Busqueda, BusquedaCriterios, DetallePromocionDemanda, EliminarAcuse, SubirAcuse } from '../../actions/buzon/promocionActions';
import { ObtenerJuzgadosDistritoMateria } from '../../actions/catalogos/juzgadoActions';

import { ObtenerMateria } from '../../actions/catalogos/materiaActions';

export const BusquedaScreen = () => {
    
    const [rows, setRows] = useState( [] );

    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, resetPage] = usePages( 5 );

    const [openDetalle, setOpenDetalle] = useState( false );
    const [openEscrito, setOpenEscrito] = useState( false );
    const [openAcuse, setOpenAcuse] = useState( false );
    const [openConfirmacion, setOpenConfirmacion] = useState( false );
    const [openArchivoAnexo, setOpenArchivoAnexo] = useState( false );  
    const [openConsolidado, setOpenConsolidado] = useState( false );

    const [recordsCount, setRecordsCount] = useState( 0 );

    const [acuse, setAcuse] = useState( null );
    const [acuseSistemaGestion, setAcuseSistemaGestion] = useState( '' );
    const [escrito, setEscrito] = useState( '' );

    const [folio, setFolio] = useState( '' );
    const [referencia, setReferencia] = useState( '' );
    const [presentante, setPresentante] = useState( '' );
    const [materia , setMateria] = useState(0);
    const [fecha, setFecha] =useState('');

    const [materiaArray, setMateriaArray] = useState( [ ] );  

    const [juzgado, setJuzgado] = useState( 0 );
    const [juzgadoTemp, setJuzgadoTemp] = useState( 0 );
    const [juzgadoArray, setJuzgadoArray] = useState( [ ] );

    const [detalle , setDetalle] = useState( {} );

    const [idPromocion , setIdPromocion] = useState( '' );

    const [filtroAplicado, setFiltroAplicado] = useState( false );    

    const [archivoAnexo, setArchivoAnexo] = useState( '' );

    const [loading, setLoading] = useState( false );   
    const [loadingSearch, setLoadingSearch] = useState( false );   
    const [loadingDetalle, setLoadingDetalle] = useState( false );   
    const [loadingUpload, setLoadingUpload] = useState( false );   
    const [loadingDelete, setLoadingDelete] = useState( false );   
    const [loadingActualizarJuzgado, setLoadingActualizarJuzgado] = useState( false );   
    const [loadingConsolidar, setLoadingConsolidar] = useState( false );

    const [idReferencia, setIdReferencia] = useState( 0 );   

    const [alert, setAlert] = useState( {} );
    
    const handleChangeAcuse = ({ file }, status) => { 
        if( status === "done" ) { 
            setAcuse( file ); 
        } 
        else if( status === "removed" ){
            setAcuse( null );
        }
    }

    const handleClearFiltro = () => {

        setFiltroAplicado( false ); 
        setFolio( '' );
        setReferencia( '' );
        setPresentante( '' );
        setMateria(0);
        setFecha('');
        setRecordsCount( 0 );

        resetPage();
        setRows( [] );
    }

    const handleBuscarPromociones = () => { 

        setLoadingSearch( true );
        setFiltroAplicado( true );
        setRecordsCount( 0 );
        
    }

    const handleDetallePromocion = async ( id ) => {

        setOpenDetalle( true ); 
        setLoadingDetalle( true );

        await DetallePromocionDemanda({ idPromocion: id }).then( response => {

            if( response ){

                if( response.data ){

                    setTimeout(() => {

                        setDetalle( response.data ?? {} );
                        setLoadingDetalle( false );

                    },1000);

                }

            }

        });           
        
    }

    const handleSubirAcuse = async ( id ) => {
        
        setLoadingUpload( true );      

        let formData = new FormData();
		    formData.append('IdPromocion', idPromocion);  
            formData.append('AcuseSistemaGestion', acuse); 
            formData.append('IdJuzgado', juzgado); 

        await SubirAcuse( formData ).then( response => {

            if( response ){

                if( response.status === 200){
                
                    if( response.data ){

                        const { success, message } = response.data;

                        if( success ){

                            setTimeout(() => {

                                setLoadingUpload( false );
                                setOpenDetalle( false );
                                
                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                }); 

                                setDetalle( {} );
                                setJuzgado( 0 );
                                setJuzgadoArray( [] );

                            }, 1000);

                        }
                        else{
                            
                            setTimeout(() => {

                                setLoadingUpload( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                }); 

                            }, 1000);  

                        }
                        
                    } 

                }

            }

        });
            
    }

    const eliminarAcuse = ( id ) => {
        
        setIdReferencia( id );
        setOpenConfirmacion( true );

    }

    const handleEliminarAcuse = async () => {

        setOpenConfirmacion( false );
        setLoadingDelete( true );

        await EliminarAcuse({ idPromocion: idReferencia }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        setTimeout(() => {

                            setDetalle({ ...detalle, acuseSistemaGestion: '' });

                            setRows(
                                rows.map( ( elem ) => {
                                    if( elem.idPromocion === idReferencia ){
                                        elem.acuseSistemaGestion = '';
                                    }
                                    return elem;
                                })
                            );

                            setLoadingDelete( false );

                        }, 1000);

                    }
                    else{
                        setLoadingDelete( false );
                    }

                }
                else{
                    setLoadingDelete( false );
                }

            }

        });           
        
    }  

    const handleObtenerJuzgados = async ( idMateria, idDistritoJudicial, idJuzgado ) => {

        setJuzgado( 0 );
        setJuzgadoArray( [] );
        
        await ObtenerJuzgadosDistritoMateria({ idMateria, idDistritoJudicial }).then(response => {
            
            if( response ){

                if( response.status === 200 ){
                    setJuzgadoArray( response.data );
                    
                    if( idJuzgado !== 0 ){
                        setJuzgadoTemp( idJuzgado );
                    }
                }

            }
            
        });

    }

    const handleActualizarJuzgado = async ( id ) => {

        setLoadingActualizarJuzgado( true );

        await ActualizarPromocionJuzgado({ idPromocion: id, idJuzgado: juzgado }).then( response => {

            if( response ){

                if( response.status === 200 ){

                    if( response.data ){

                        const { success, message } = response.data;
                       
                        if( success ){

                            setTimeout(() => {

                                setRows(
                                    rows.map( ( elem ) => {
                                        if( elem.idPromocion === id ){
                                            elem.idJuzgado = juzgado;
                                        }
                                        return elem;
                                    })
                                );

                                setLoadingActualizarJuzgado( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'success'
                                }); 

                            }, 1000);
                        }
                        else{
                            setTimeout(() => {
                                
                                setLoadingActualizarJuzgado( false );

                                setAlert( {
                                    open: true,
                                    msg: message,
                                    type: 'error'
                                }); 

                            }, 1000);
                        }

                    }
                    else{
                        setTimeout(() => {
                                
                            setLoadingActualizarJuzgado( false );

                            setAlert( {
                                open: true,
                                msg: 'Ocurrio un error, contacte con el administrador del sistema',
                                type: 'error'
                            }); 

                        }, 1000);
                    }

                }
                else{
                    setLoadingDelete( false );
                }

            }

        });     
    }

    useEffect(() => {
        
        if( juzgadoArray.length > 0 && juzgadoTemp !== 0 ){
            setJuzgado( juzgadoTemp );
        }
        
    }, [ juzgadoArray, juzgadoTemp ])
    
    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );           

            await Busqueda({ page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [] );             
                            setLoading( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                    }
                }
                else{
                    setLoading( false );
                }
            })
        }         
      
        if( !filtroAplicado ){
            Obtener();          
        }
        
    }, [ filtroAplicado, page, rowsPerPage ])  

    useEffect(() => {

        async function Obtener(){
            
            setRows( [ ] );    
            setLoading( true );           

            await BusquedaCriterios({ folio: folio === '' ? 0 : folio, referencia, presentante, idMateria: materia>0 ? materia: 0, fecha  , page, rowsPerPage }).then( response => {
            
                if( response ){

                    if( response.status === 200 ){      
                        
                        setTimeout(() => {
                            
                            setRecordsCount( response.data.recordsCount ?? 0 );
                            setRows( response.data.records ?? [] );             
                            setLoading( false );
                            setLoadingSearch( false );
                
                        }, 700);
                        
                    }
                    else{
                        setLoading( false );
                        setLoadingSearch( false );
                    }
                }
                else{
                    setLoading( false );
                    setLoadingSearch( false );
                }
            })
        }         
      
        if( filtroAplicado ){
            Obtener();
        }
        
    }, [ filtroAplicado, folio, referencia, presentante, materia, fecha, page, rowsPerPage ]) 
    
    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerMateria().then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setMateriaArray( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [  ]);
    
    return (
        
        <>
            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ () => setAlert( { open:false }) }>
                        <Alert onClose={ () => setAlert( { open:false }) } variant= "filled" severity={ alert.type } sx={{ width: '100%' }}  >
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Grid container spacing={3} sx={{ mt: 1 }}>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='folio'
                        label='Folio Buzón Electrónico'
                        autoComplete='off'
                        value={ folio }
                        onChange={ ( e ) => setFolio( e.target.value ) }
                        fullWidth
                        disabled={ filtroAplicado }
                    />

                </Grid>

                <Grid item md={4} xs={12} >  

                    <TextField
                        name='folioExpediente'
                        label='Número expediente o folio'
                        autoComplete='off'
                        value={referencia}
                        onChange={ (e) => setReferencia( e.target.value ) }
                        fullWidth
                        disabled={ filtroAplicado }
                    />

                </Grid>

                <Grid item md={4} xs={12} >  

                    <TextField                              
                        label='Presentante'
                        autoComplete='off'
                        fullWidth
                        value={ presentante }
                        onChange={ ( e ) => setPresentante( e.target.value ) }      
                        disabled={ filtroAplicado }                       
                    />

                </Grid>
                
                <Grid item md={4} xs={12} >  

                    <FormControl fullWidth>

                        <InputLabel id='select-materia-label'>Materia</InputLabel>
                    
                        <Select
                            labelId='select-materia-label'
                            id='select-materia'                                            
                            label='Materia'
                            name='materia'
                            value={ materia }                                           
                            onChange={ ( e ) =>  setMateria(e.target.value) }
                            disabled={ filtroAplicado }
                        >
                            <MenuItem value={0}>Seleccione una opción</MenuItem>
                            {
                                materiaArray.map( (elem) => (
                                    <MenuItem key={ elem.idMateria} value={ elem.idMateria}> {elem.descripcion} </MenuItem>
                                ))
                            }
                            
                        </Select>               

                    </FormControl>

                </Grid>

                <Grid item md={4} xs={12} >  

                    <TextField                              
                        label='Fecha de registro'
                        type='date'
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }} 
                        value={ fecha }
                        onChange={ ( e ) => setFecha( e.target.value ) }      
                        disabled={ filtroAplicado }                       
                    />

                </Grid>

                <Grid item xs={4} >   

                    <LoadingButton
                        variant='contained'
                        startIcon={ filtroAplicado ? <ClearIcon /> : <SearchIcon /> }
                        fullWidth
                        disabled={ folio === '' && presentante === '' && referencia === '' && materia === 0  && fecha === '' }
                        onClick={ () => { filtroAplicado ? handleClearFiltro() : handleBuscarPromociones() } }
                        loading={ loadingSearch }
                        color={ filtroAplicado ? 'inherit' : 'primary' }
                    >
                        { filtroAplicado ? 'limpiar filtro' : 'Buscar'}
                    </LoadingButton>                       

                </Grid>

                <Grid item xs={12} md={12} sx={{ mt: 1 }}> 
                
                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>        
                                   
                                    <TableCell style={{ width:'15%' }}> TIPO DE TRÁMITE </TableCell>
                                    <TableCell style={{ width:'10%' }}> FOLIO BÚZON ELECTRÓNICO </TableCell>
                                    <TableCell style={{ width:'15%' }}> REFERENCIA </TableCell>
                                    <TableCell style={{ width:'15%' }}> FECHA DE RECEPCIÓN </TableCell>
                                    <TableCell style={{ width:'25%' }}> PRESENTANTE </TableCell>
                                    <TableCell style={{ width:'15%' }}> MATERIA </TableCell>
                                    <TableCell style={{ width:'2%', textAlign: 'center' }}> DETALLE </TableCell>
                                    <TableCell style={{ width:'2%', textAlign: 'center' }}> ESCRITO </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}> </TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row ) => (
                                        
                                        <TableRow key={ row.idPromocion} >
                                            
                                            <TableCell> { row.tipoTramite } </TableCell>

                                            <TableCell sx={{ fontWeight: 'bold' }}> { row.folio ? "F-" + ( '00000000' + row.folio.toString() ).substring(row.folio.toString().length) : '' } </TableCell>
                                            
                                            <TableCell> { row.referencia } </TableCell>
                                            
                                            <TableCell> { row.fechaHora ? moment( row.fechaHora ).format('DD-MM-YYYY hh:mm a') : '' } </TableCell>
                                            
                                            <TableCell> { row.presentante } </TableCell>

                                            <TableCell> { row.materia } </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>  

                                                <Button
                                                    onClick={ () => { setIdPromocion(row.idPromocion) ; handleDetallePromocion( row.idPromocion ); handleObtenerJuzgados( row.idMateria, row.idDistritoJudicial, row.idJuzgado ); } }
                                                    color='primary'   
                                                    size='small'
                                                    variant='contained'    
                                                >
                                                    <InfoIcon />
                                                </Button>
                                                
                                                {
                                                    row.sistemaGestion
                                                    &&
                                                        <Chip size='small' variant='outlined' sx={{ fontWeight: 'bold', mt: 1 }} label='Capturado en SAJI' />
                                                }

                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}>   
                                                
                                                <Button
                                                    onClick={ () => { setEscrito( row.escrito ); setOpenEscrito( true ); } }
                                                    color='primary'   
                                                    size='small'
                                                    variant='contained'    
                                                >
                                                    <FileCopyIcon />
                                                </Button>

                                            </TableCell>

                                            <TableCell> { row.app && <PhoneAndroidIcon /> } </TableCell>

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron promociones'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={2}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                    </TableCell>

                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={10}
                                        count={recordsCount}                                        
                                        rowsPerPage={rowsPerPage}
                                        page={page}                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>
                
            </Grid>

            <Dialog 
                open={ openDetalle } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="lg" 
            >

                <DialogTitle variant='h4'>
                    { detalle.folio ? "F-" + ( '00000000' + detalle.folio.toString() ).substring(detalle.folio.toString().length) : '' }
                </DialogTitle>
               
                <DialogContent>
                  
                    {
                        loadingDetalle
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                            </Grid>
                        :
                            <Grid container spacing={3} >                                                
                            
                                    <Grid item sm={4} xs={4} >
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Presentante
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={8} xs={8} >
                                        <Typography variant='body2' sx={{ textTransform: '' }}>
                                            <strong>{detalle.presentante }</strong> <br />
                                            { detalle.correoElectronico }<br />
                                            {  detalle.telefonoCelular }
                                        </Typography>
                                    </Grid>
                                
                                    <Grid item sm={4} xs={4} >
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Tipo de trámite	
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={8} xs={8} >
                                        <Typography variant='body2' sx={{ textTransform: '' }}>
                                            {detalle.tipoTramite } 
                                        </Typography>
                                    </Grid>

                                    {
                                        detalle.tipoJuicio
                                        &&
                                            <Grid item sm={4} xs={4} >
                                                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                    Tipo de juicio	
                                                </Typography>
                                            </Grid>
                                    }
                                   
                                   {
                                        detalle.tipoJuicio
                                        &&
                                            <Grid item sm={8} xs={8} >
                                                <Typography variant='body2' sx={{ textTransform: '' }}>
                                                    {detalle.tipoJuicio}
                                                </Typography>
                                            </Grid>
                                    }                                   

                                    <Grid item sm={4} xs={4} >
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Matería
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={8} xs={8} >
                                        <Typography variant='body2' sx={{ textTransform: '' }}>
                                            { detalle.materia }
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={4} xs={4} >
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Órgano jurisdiccional
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={8} xs={8} >
                                        <Typography variant='body2' sx={{ textTransform: '' }}>
                                            <b>{ detalle.oficialia }</b> <br />
                                            { detalle.juzgado }
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={4} xs={4} >
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Estatus
                                        </Typography>
                                    </Grid>

                                    <Grid item sm={8} xs={8} >
                                        <Typography variant='body2' sx={{ textTransform: '' }}>
                                            { detalle.estatus }
                                        </Typography>
                                    </Grid>

                                    {
                                        detalle?.anexos?.length !== 0
                                        &&
                                        <>
                                            <Grid item sm={4} xs={4} >
                                                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                    Anexos { '(' + detalle?.anexos?.length + ')' }
                                                </Typography>
                                            </Grid>

                                            <Grid item sm={8} xs={8}>

                                                <List disablePadding>

                                                    {
                                                        detalle?.anexos?.map( ( elem, index ) => (
                                                            
                                                            <ListItemButton disableGutters key={ index } onClick={ () => { setArchivoAnexo( elem.url ); setOpenArchivoAnexo( true ); } }>
                                                                <ListItemIcon>
                                                                    <FileCopyIcon color='primary' />
                                                                </ListItemIcon>
                                                                <ListItemText primary={ elem.archivo } />
                                                            </ListItemButton>

                                                        ))
                                                    }

                                                </List>

                                            </Grid>   

                                        </>
                                    }

                                    <Grid item sm={4} xs={4} >
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Consolidar documentos
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item sm={8} xs={8} >
                                        <LoadingButton 
                                            variant='contained' 
                                            color='primary'
                                            onClick={  () => { setOpenConsolidado( true); setLoadingConsolidar( true ); } }
                                            startIcon={ <PictureAsPdfIcon fontSize='small' /> }                                            
                                            disabled={ detalle.escrito === '' || detalle.acuse === '' || detalle.sistemaGestion }
                                        >
                                            Consolidar
                                        </LoadingButton>
                                    </Grid>

                                    {
                                        ( detalle?.idTipoTramite === 1 || detalle?.idTipoTramite === 2 )
                                        &&
                                        <>
                                            <Grid item sm={4} xs={4} >
                                                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                    Selecciona el juzgado al que se turnará el trámite:
                                                </Typography>
                                            </Grid>

                                            <Grid item md={7} xs={7} > 

                                                <FormControl fullWidth>

                                                    <InputLabel id='select-juzgado-label'>Juzgado</InputLabel>

                                                    <Select
                                                        labelId='select-juzgado-label'
                                                        id='select-juzgado'                                            
                                                        label='Juzgado'
                                                        name='juzgado'
                                                        value={ juzgado }                                           
                                                        onChange={ (e ) => { setJuzgado(e.target.value) } }
                                                        disabled={ detalle.sistemaGestion }
                                                    >
                                                        <MenuItem value={0}>Seleccione una opción</MenuItem>
                                                        {
                                                            juzgadoArray.map( (elem) => (
                                                                <MenuItem key={ elem.idJuzgado} value={ elem.idJuzgado}> {elem.descripcion} </MenuItem>
                                                            ))
                                                        }
                                                        
                                                    </Select>               

                                                </FormControl>

                                            </Grid>

                                            <Grid item sm={1} xs={1}>
                                                <IconButton 
                                                    onClick={ () => handleActualizarJuzgado( detalle?.idPromocion ?? 0 ) }
                                                    color='primary'
                                                    disabled={ juzgado === juzgadoTemp }
                                                >
                                                    {
                                                        loadingActualizarJuzgado
                                                        ?
                                                            <CircularProgress size={20} />
                                                        :
                                                            <SaveIcon />
                                                    }
                                                </IconButton>
                                            </Grid>
                                        </>
                                    }
                                    
                                    <Grid item sm={4} xs={4} >
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Acuse de registro
                                        </Typography>
                                    </Grid>

                                    <Grid item md={8} xs={8} >
                                    
                                        {
                                            detalle.acuseSistemaGestion === ''
                                            &&
                                                <Dropzone
                                                    onChangeStatus={ handleChangeAcuse }
                                                    accept="application/pdf"
                                                    SubmitButtonComponent={null}
                                                    inputContent={ 
                                                        <Typography key={0} variant='body2' sx={{ textAlign: 'center' }}>
                                                            <strong style={{ fontSize: 18 }}>Añadir acuse.</strong>
                                                        </Typography> 
                                                    }
                                                    maxFiles={1}
                                                    styles={{
                                                        dropzone: { border: '1px dashed #252B50' },
                                                        inputLabel:{ color: '#252B50', fontSize: 14, padding: '15px' }
                                                    }}
                                                />
                                        }

                                        {
                                            detalle.acuseSistemaGestion !== ''
                                            && 
                                            <>
                                                <Button 
                                                    variant='contained' 
                                                    startIcon={ <FileCopyIcon fontSize='small' /> }
                                                    color='primary'
                                                    onClick={ () => { setOpenAcuse( true ); setAcuseSistemaGestion( detalle.acuseSistemaGestion  ); } }
                                                >
                                                    Ver acuse
                                                </Button>

                                                &nbsp;

                                                {
                                                    !detalle.sistemaGestion 
                                                    &&
                                                        <IconButton 
                                                            onClick={ () => eliminarAcuse( detalle.idPromocion ) }
                                                            color='primary'
                                                        >
                                                            {
                                                                loadingDelete
                                                                ?
                                                                    <CircularProgress size={20} />
                                                                :
                                                                    <DeleteIcon />
                                                            }
                                                        </IconButton>
                                                }
                                                
                                            </>
                                        }

                                    </Grid>

                            </Grid>
                    }
                  
                </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenDetalle( false ); setDetalle( {} ); } }>
                       Cerrar
                   </Button>
                    
                    {
                        detalle.acuseSistemaGestion === ''
                        &&
                        <LoadingButton 
                            variant='contained' 
                            color="primary" 
                            onClick={ () => handleSubirAcuse( detalle.idPromocion ) } 
                            startIcon={ <SaveIcon /> } 
                            loading={ loadingUpload }
                            disabled={ acuse === null }
                        >
                            Guardar
                        </LoadingButton>
                    }

               </DialogActions>                

            </Dialog> 

            <Dialog 
                open={ openEscrito } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    ESCRITO
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                            <iframe title='acuse' src={ escrito } height="500px" width="100%"></iframe>

                        </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenEscrito( false ); setEscrito( '' ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

            <Dialog 
                open={ openAcuse } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Acuse Sistema de Gestión
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                            <iframe title='acuse' src={ acuseSistemaGestion } height="500px" width="100%"></iframe>

                        </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenAcuse( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

            <Dialog
                open={openConfirmacion}
                onClose={ () => { } }
            >
                <DialogTitle>
                    Confirmación
                </DialogTitle>
               
                <DialogContent>

                    <DialogContentText sx={{ color: 'black' }}>
                        ¿Desea eliminar el acuse del sistema de gestión?
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpenConfirmacion( false ); setIdReferencia( 0 ); } }>Cancelar</Button>
                    <Button variant='contained' color='primary' onClick={ handleEliminarAcuse } >
                        Aceptar
                    </Button>
                </DialogActions>

            </Dialog>

            <Dialog 
                open={ openArchivoAnexo } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Anexo
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                            <iframe title='acuse' src={ archivoAnexo } height="500px" width="100%"></iframe>

                        </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenArchivoAnexo( false ); setArchivoAnexo( '' ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 

            <Dialog 
                open={ openConsolidado } 
                onClose={ () => {} } 
                fullWidth
                maxWidth="md" 
            >

                <DialogTitle>
                    Documento consolidado
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >      

                        {
                            loadingConsolidar
                            &&
                                <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                                    <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                </Grid>
                        }                                          
                   
                        <Grid item md={12} xs={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                            
                            <iframe 
                                title='consolidado' 
                                src={ process.env.REACT_APP_URL_BASE + '/Api/Promociones/ConsolidarDocumentos/' + detalle?.id ?? '' } 
                                height={ loadingConsolidar ? "0px" : "500px" } 
                                width="100%"
                                onLoad={ () => setLoadingConsolidar( false ) }
                                style={{ opacity: loadingConsolidar ? 0 : 1 }}
                            >
                            </iframe>

                        </Grid>
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                   <Button color="primary" onClick={ () => { setOpenConsolidado( false ); } }>
                       Cerrar
                   </Button>

               </DialogActions>                

            </Dialog> 
            
        </> 

    )

}
