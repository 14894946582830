import React, { useEffect, useState } from 'react';

import { Alert, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, List, ListItem, Radio, RadioGroup, Snackbar, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import InfoIcon from '@mui/icons-material/Info';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import moment from 'moment';

import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { DetalleBoletas, GuardarAcuseBoleta, ListadoBoletasJuzgado } from '../../actions/boleta/boletaActions';

export const BoletasEnviadasScreen = () => {       

    const [rows, setRows] = useState( [] );
    const [rowsPersonas, setRowsPersonas] = useState( [] );    
    const [boleta, setBoleta] = useState( {} );

    const [open, setOpen] = useState( false );
    const [openAcuse, setOpenAcuse] = useState( false );   

    const [recordsCount, setRecordsCount] = useState( 0 );
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages( 10 );
    const [pagePersonas, rowsPerPagePersonas, handleChangePagePersonas, handleChangeRowsPerPagePersonas] = usePages( 5 ); 

    const [loading, setLoading] = useState( true ); 
    const [loadingAgregarAcuseBoleta, setLoadingAgregarAcuseBoleta] = useState( false );
    const [loadingDetalle, setLoadingDetalle] = useState( false );

    const [cancelarBoleta, setCancelarBoleta] = useState( false );
    const [errores, setErrores] = useState( {} );
    
    const [referencia, setReferencia] = useState( '' );

    const [archivo, setArchivo] = useState( null );
    const [motivoCancelacion, setMotivoCancelacion] = useState( '' );

    const [alert, setAlert] = useState( {} );

    const [reload, setReload] = useState( false );

    const handleChangeCancelarBoleta = ( value ) => {

        setCancelarBoleta( value ); 
        setMotivoCancelacion( '' ); 
        setArchivo( null );
        setErrores( {} );
    }

    const handleChangeBoleta = ({ file }, status) => { 
        if( status === 'done' ){  
            setArchivo( file );  
        }
        else if( status === 'removed' ){
            setArchivo( null );  
        }
    }

    const validateFields = () => {
        
        let valid = true;
        let errores = {};

        if ( !cancelarBoleta && archivo === null ) {
            valid = false
            errores.archivo = 'Debes cargar un archivo'            
        }

        else if ( cancelarBoleta && motivoCancelacion === ''  ) {
            valid = false
            errores.motivoCancelacion = 'Escribe el motivo de cancelación'            
        }         

        setErrores( errores );        
        
        return valid;
    }

    const handleClose = (event, reason) => {
        
        if (reason === 'clickaway') {
            return;
        }
        
        setAlert({ open: false });
    }

    const handleClearFiels = () => {

        setCancelarBoleta( false );
        setArchivo( null );        
        setMotivoCancelacion( '' );
        setErrores( {});
        
    }
   
    const handleDetalleBoleta = async ( id ) => {

        setOpen( true ); 
        setLoadingDetalle( true );

        await DetalleBoletas({ idBoleta: id }).then( response => {

            if( response ){

                if( response.data ){

                    setTimeout(() => {

                        setBoleta( response.data ?? {} );
                        
                        setRowsPersonas(response.data.personasNotificar ?? [] );    

                        setLoadingDetalle( false );

                    }, 1000);
                }
            }
        });          
        
    }

    const handleGuardarAcuse = async () => {            

        const valid = validateFields();

        if( !valid ){
            return false;
        } 

        const formData = new FormData();
            formData.append('id', referencia );      
            formData.append('archivo', archivo);  
            formData.append('motivoCancelacion', motivoCancelacion);

        setLoadingAgregarAcuseBoleta( true );

        await GuardarAcuseBoleta( formData ).then( response => {

            if( response ){

                const { success, message } = response.data;

                if( success ){                     

                    setTimeout(() => {   

                        setReload( true );

                        setOpenAcuse( false );
                        setLoadingAgregarAcuseBoleta( false ); 
                        handleClearFiels();

                        setAlert( {
                            open: true,
                            msg: message,
                            type: 'success'
                        } );  
                        
                    },1000);                                

                }                
                else {

                    setTimeout(() => {   
                        
                        setAlert( {
                            open: true,
                            msg: message,
                            type: 'error'
                        } ); 

                        setLoadingAgregarAcuseBoleta( false );

                    },1000);

                }

            }
        });     

    }

    useEffect(() => {
      
        async function Obtener(){

            setRows( [] );
            setLoading( true );
           
            await ListadoBoletasJuzgado({ page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setRows(response.data.records ?? [] );
                            setRecordsCount(response.data.recordsCount);
                            
                            setLoading( false );
                            
                        },1000);
                    }
                }
            });         
        }      

        Obtener();  

    }, [ page, rowsPerPage ])   

    useEffect(() => {
      
        async function Obtener(){

            setRows( [] );
            setLoading( true );
           
            await ListadoBoletasJuzgado({ page, rowsPerPage }).then( response => {

                if( response ){

                    if( response.data ){

                        setTimeout(() => {
                            
                            setRows(response.data.records ?? [] );
                            setRecordsCount(response.data.recordsCount);
                            
                            setLoading( false );
                            setReload( false );
                            
                        },1000);
                    }
                }
            });         
        }      

        if( reload ){
            Obtener();  
        }

    }, [ reload, page, rowsPerPage ])   

    return (
        <>

            {
                alert.msg && (
                    <Snackbar open={ alert.open } anchorOrigin={{ vertical: 'bottom',horizontal: 'right' }} autoHideDuration={ 4000 } onClose={ handleClose }>
                        <Alert onClose={ handleClose } variant= "filled" severity={ alert.type } sx={{ width: '100%' }}  >
                            { alert.msg }
                        </Alert>
                    </Snackbar>
                )
            }

            <Grid container spacing={3}>

                <Grid item md={12} xs={12} >

                    <TableContainer>

                        <Table>
                            
                            <TableHead>

                                <TableRow>        

                                    <TableCell style={{ width:'15%' }}> FECHA </TableCell>
                                    <TableCell style={{ width:'15%' }}> BOLETA </TableCell>
                                    <TableCell style={{ width:'15%' }}> EXPEDIENTE </TableCell>
                                    <TableCell style={{ width:'15%' }}> TIPO DE DILIGENCIA </TableCell>
                                    <TableCell style={{ width:'25%' }}> SOLICITANTE </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}> DETALLE </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}> ACUSE </TableCell>
                                    <TableCell style={{ width:'1%', textAlign: 'center' }}></TableCell>

                                </TableRow>

                            </TableHead>

                            <TableBody>

                                {
                                    rows.map( ( row, i ) => (

                                        <TableRow key={ row.id } >                                            
                                           
                                            <TableCell> { row.fechaSolicita ? moment(row?.fechaSolicita).format('DD/MM/YYYY hh:mm a') :'' } </TableCell>                                            
                                            <TableCell sx={{ fontWeight: 'bold' }}> { row.numeroBoleta } </TableCell>
                                            <TableCell> { row.numeroExpediente } </TableCell>
                                            <TableCell> { row.tipoDiligencia } </TableCell>
                                            <TableCell> 
                                                { row.solicitanteNombre } { row.solicitanteApellido1 } { row.solicitanteApellido2 } <br />
                                                <strong> { row.solicitanteTelefonoCelular } </strong>
                                            </TableCell>
                                          
                                            <TableCell sx={{ textAlign: 'center' }}> 

                                                <Button
                                                    color='primary'   
                                                    size='small'
                                                    variant='contained'                                           
                                                    onClick={ () => { handleDetalleBoleta( row.id ) } }
                                                >
                                                    <InfoIcon />
                                                </Button>  

                                                {
                                                    row.sistemaGestion
                                                    &&
                                                        <Chip size='small' variant='outlined' sx={{ fontWeight: 'bold', mt: 1 }} label='Revisar boleta en SAJI' />
                                                }
                                
                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign: 'center' }}> 

                                                {
                                                    !row.sistemaGestion
                                                    &&
                                                        <Button
                                                            color='primary'   
                                                            size='small'
                                                            variant='contained'                                                  
                                                            onClick={ () => { setOpenAcuse( true ); setReferencia( row.id ); } }
                                                        >
                                                            <UploadFileIcon />
                                                        </Button>                                                                                              
                                                }
                                            
                                            </TableCell>     

                                            <TableCell> { row.app && <PhoneAndroidIcon /> } </TableCell>                                    

                                        </TableRow>

                                    ))
                                }

                                { 
                                    rows.length === 0 && (
                                        <TableRow style={{ height: 53 }}>

                                            <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold' }}> 
                                                {
                                                    loading
                                                    ?
                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                    :
                                                        ''
                                                }
                                                {
                                                    !loading && rows.length === 0
                                                    ?
                                                        'No se encontraron boletas pendientes'
                                                    :
                                                        ''
                                                }
                                            </TableCell>

                                        </TableRow>
                                    )
                                }
                            </TableBody>

                            <TableFooter>

                                <TableRow>

                                    <TableCell colSpan={2}>
                                        <Typography variant='body2' color='black' sx={{ fontWeight: 'bold' }}> Total de registros: { recordsCount } </Typography>
                                    </TableCell> 
                        
                                    <TablePagination
                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                        colSpan={10}
                                        count={ recordsCount }                                        
                                        rowsPerPage={rowsPerPage}
                                        page={page}                                                                                
                                        onPageChange={ handleChangePage }
                                        onRowsPerPageChange={ handleChangeRowsPerPage }
                                        ActionsComponent={ TablePaginationActions }

                                        labelRowsPerPage="Filas por página"                                            
                                        labelDisplayedRows={
                                            ({ from, to, count }) => {
                                            return '' + from + '-' + to + ' de ' + count
                                            }
                                        }  
                                    />

                                </TableRow>

                            </TableFooter>

                        </Table>

                    </TableContainer>

                </Grid>

            </Grid>

            <Dialog
                open={open}
                onClose={ () => { } }
                maxWidth='md'
                fullWidth
            >
                <DialogTitle>
                </DialogTitle>
               
                <DialogContent>
                   
                    {
                        loadingDetalle
                        ?
                            <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
                                <CircularProgress size={40} sx={{ mt: 5, mb: 5 }} />
                            </Grid>
                        :
                            <Grid container spacing={3}>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                                        Número de boleta
                                    </Typography>   

                                </Grid>  

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='h6' sx={{ textTransform: '' }}>
                                        { boleta.numeroBoleta }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Solicitante 
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.solicitante } <br />
                                        <strong> { boleta.telefonoCelularSolicitante } </strong>
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Número de expediente
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.numeroExpediente }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Juzgado
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.juzgado }
                                    </Typography>
                                </Grid>

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Tipo de diligencia
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >
                                    <Typography variant='body2' sx={{ textTransform: '' }}>
                                        { boleta.tipoDiligencia }
                                    </Typography>
                                </Grid>

                                {
                                    boleta.observaciones
                                    &&
                                    <>
                                        <Grid item sm={3} xs={6} >
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Observaciones
                                            </Typography>
                                        </Grid>

                                        <Grid item sm={9} xs={6} >
                                            <Typography variant='body2' sx={{ textTransform: '' }}>
                                                { boleta.observaciones }
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid item sm={3} xs={6} >
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Autos a notificar
                                    </Typography>
                                </Grid>

                                <Grid item sm={9} xs={6} >

                                    <List disablePadding>  

                                        { boleta?.acuerdos?.map( ( elem, i  ) => (
                                            <ListItem key={ i } disablePadding sx={{ pb: 1 }}>
                                                <Typography variant='body2'> <strong> { elem ? moment( elem ).format('DD-MM-YYYY') : '' } </strong></Typography>                                    
                                            </ListItem>
                                        ))}                               
                                        
                                    </List>

                                </Grid>

                                <Grid item xs={12} >

                                    <Typography variant='h6'>Personas a notificar</Typography>

                                    <Divider sx={{ pb: 2 }} />

                                </Grid>

                                <Grid item md={12} xs={12} >

                                    <TableContainer>

                                        <Table>
                                            
                                            <TableHead>

                                                <TableRow>        

                                                    <TableCell style={{ width:'15%' }}> NOMBRE(S) </TableCell>
                                                    <TableCell style={{ width:'15%' }}> APELLIDO 1 </TableCell>
                                                    <TableCell style={{ width:'15%' }}> APELLIDO 2 </TableCell>
                                                    <TableCell style={{ width:'15%' }}> TIPO DE PARTE </TableCell>
                                                    <TableCell style={{ width:'30%' }}> DOMICILIO </TableCell>

                                                </TableRow>

                                            </TableHead>

                                            <TableBody>

                                                {
                                                    ( rowsPerPagePersonas > 0
                                                        ? rowsPersonas.slice(pagePersonas * rowsPerPagePersonas, pagePersonas * rowsPerPagePersonas + rowsPerPagePersonas)
                                                        : rowsPersonas
                                                    ).map( ( row, i ) => (

                                                        <TableRow key={ i } >
                                                            
                                                            <TableCell> { row.nombre } </TableCell>
                                                            <TableCell> { row.apellido1 } </TableCell>
                                                            <TableCell> { row.apellido2 } </TableCell>
                                                            <TableCell> { row.tipoParte } </TableCell>
                                                            <TableCell> { row.direccion } </TableCell>

                                                        </TableRow>

                                                    ))
                                                }

                                                { 
                                                    rowsPersonas.length === 0 && (
                                                        <TableRow style={{ height: 53 }}>

                                                            <TableCell colSpan={10} align="center"> 
                                                                No se añadieron personas a notificar
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>

                                            <TableFooter>

                                                <TableRow>
                                        
                                                    <TablePagination
                                                        rowsPerPageOptions={[ 5, 10, 15, 20 ]}
                                                        colSpan={10}
                                                        count={rowsPersonas.length}                                        
                                                        rowsPerPage={rowsPerPagePersonas}
                                                        page={pagePersonas}                                                                                
                                                        onPageChange={ handleChangePagePersonas }
                                                        onRowsPerPageChange={ handleChangeRowsPerPagePersonas }
                                                        ActionsComponent={ TablePaginationActions }

                                                        labelRowsPerPage="Filas por página"                                            
                                                        labelDisplayedRows={
                                                            ({ from, to, count }) => {
                                                            return '' + from + '-' + to + ' de ' + count
                                                            }
                                                        }  
                                                    />

                                                </TableRow>

                                            </TableFooter>

                                        </Table>

                                    </TableContainer>

                                </Grid>
                            
                            </Grid>
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={ () => { setOpen( false ) } }>Cancelar</Button>
                </DialogActions>

            </Dialog>

            <Dialog 
                open={ openAcuse } 
                onClose={ () => {} } 
                fullWidth={ true } 
                maxWidth="md" 
            >
                <DialogTitle>
                </DialogTitle>
               
               <DialogContent>
                  
                   <Grid container spacing={3} >                                                
                   
                        <Grid item md={12} xs={12} >

                            <FormControl>
                                
                                <FormLabel sx={{ color: 'black', fontWeight: 'bold' }}>¿Desea cancelar la boleta de notificación?</FormLabel>
                                
                                <RadioGroup row>

                                    <FormControlLabel value="no" control={ <Radio checked={ cancelarBoleta === false } onChange={ () => { handleChangeCancelarBoleta( false ); } } /> } label="No" />
                                    <FormControlLabel value="si" control={ <Radio checked={ cancelarBoleta === true } onChange={ () => { handleChangeCancelarBoleta( true ); } } /> } label="Si" />
                                
                                </RadioGroup>

                            </FormControl>

                        </Grid>

                        {
                            !cancelarBoleta
                            &&
                            <Grid item md={12} xs={12} >

                                <Dropzone
                                    onChangeStatus={ handleChangeBoleta }
                                    accept=".pdf"
                                    maxFiles={1}
                                    SubmitButtonComponent={null}
                                    inputContent={ 
                                        <Typography key={0} variant='body2' sx={{ textAlign: 'center' }}>
                                            <strong style={{ fontSize: 18 }}>Añadir cédula de notificación.</strong>
                                        </Typography> 
                                    }
                                    styles={{
                                        dropzone: { border: '1px dashed #252B50' },
                                        inputLabel:{ color: '#252B50', fontSize: 14, padding: '15px' }
                                    }}
                                />

                                {
                                    errores.archivo && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.archivo } </Alert>)                          
                                }

                            </Grid>
                        }

                        {
                            cancelarBoleta
                            &&
                            <Grid item md={12} xs={12} >

                                <TextField
                                    name='motivoCancelacion'
                                    label='Motivo de cancelación'
                                    fullWidth
                                    multiline
                                    rows={5}                                    
                                    value={ motivoCancelacion }
                                    onChange={ (e) => setMotivoCancelacion( e.target.value ) }                                    
                                />

                                {
                                    errores.motivoCancelacion && ( <Alert severity='warning' variant='filled' sx={{ mt: 1 }}> { errores.motivoCancelacion } </Alert>)                          
                                }

                            </Grid>
                        }   
                      
                   </Grid>
                  
               </DialogContent>

               <DialogActions>

                  <Button color="primary" onClick={ () => { handleClearFiels(); setOpenAcuse( false ); } }>
                       Cerrar
                   </Button>

                   <LoadingButton color="primary" variant='contained' onClick={ handleGuardarAcuse } startIcon={ <SaveIcon /> } loading={ loadingAgregarAcuseBoleta } >
                       Guardar
                   </LoadingButton>

               </DialogActions>                

            </Dialog> 

        </>
    )

}
