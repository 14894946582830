import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, FormControl, Grid, IconButton, InputLabel, LinearProgress, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, 
  TableRow, TextField, Toolbar, Typography 
} from '@mui/material';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';

import { NavbarInicio } from '../ui/NavbarInicio';

import { usePages } from '../../hooks/usePages';

import { TablePaginationActions } from '../../helpers/TablePaginationActions';

import { TiposInstancias} from '../../actions/catalogos/tipoInstanciaActions';
import { ObtenerMunicipios } from '../../actions/catalogos/municipioActions';

import { usePermisos } from '../../hooks/usePermisos';

import { NotFoundScreen } from '../auth/NotFoundScreen';

import { ActualizarJuzgado, AgregarJuzgado, Juzgados } from '../../actions/catalogos/juzgadoActions';
import { TipoJuzgado } from '../../actions/catalogos/tipoJuzgadoActions';
import { Materias } from '../../actions/catalogos/materiaActions';
import { ObtenerDistritosJudiciales } from '../../actions/catalogos/distritoJudicialActions';
import { ObtenerOficialias } from '../../actions/catalogos/oficialiaActions';
import { Aplicaciones } from '../../actions/catalogos/aplicacionActions';
    
export const JuzgadoScreen = ( ) => {

    const { tipoUsuario, loading } = usePermisos();

    const navigate = useNavigate();

    const [errors, setErrors] = useState({ });

    const [rows, setRows] = useState( [ ] );

    const [tipoJuzgado, setTipoJuzgado] = useState( 0 );
    const [tipoJuzgadoArray, setTipoJuzgadoArray] = useState( [ ] );

    const [tipoInstancia, setTipoInstancia] = useState( 0 );
    const [tipoInstanciaArray, setTipoInstanciaArray] = useState( [ ] );

    const [materia, setMateria] = useState( 0 );
    const [materiaArray, setMateriaArray] = useState( [ ] );

    const [distritoJudicial, setDistritoJudicial] = useState( 0 );
    const [distritoJudicialArray, setDistritoJudicialArray] = useState( [ ] );

    const [oficialia, setOficialia] = useState( 0 );
    const [oficialiaArray, setOficialiaArray] = useState( [ ] );

    const [municipio, setMunicipio] = useState( 0 );
    const [municipioArray, setMunicipioArray] = useState( [ ] );

    const [aplicacion, setAplicacion] = useState( '' );
    const [aplicacionArray, setAplicacionArray] = useState( [ ] );

    const [nombreCorto, setNombreCorto] = useState( '' );
    const [descripcion, setDescripcion] = useState( '' );
    const [origen, setOrigen] = useState( '' );
    const [idReferencia, setIdReferencia] = useState( '' );
    const [opcion, setOpcion] = useState( '' );

    const [loadingTable, setLoadingTable] = useState( true );
    const [loadingSave, setLoadingSave] = useState( false );
    const [openModal, setOpenModal] = useState( false );
  
    const [page, rowsPerPage, handleChangePage, handleChangeRowsPerPage] = usePages();
  
    const handleChangeSwitch = ( id, value ) => {

        const newRows = rows.map( (row) => { if(row.idJuzgado === id){ row.activo = value; return row; } else{ return row; } } );
        setRows( newRows );        

        const juzgado = rows.filter( function(row){ return row.idJuzgado === id; } );
        cambiarEstatus( juzgado[0] );
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setErrors( { } );
    }

    const cambiarEstatus = async ( { idJuzgado, descripcion, nombreCorto, activo, idOrigen, tipoJuzgado, tipoInstancia, materia, municipio, distritoJudicial, oficialia, aplicacion } ) => {

        const params = {
            idJuzgado,
            idTipoJuzgado: tipoJuzgado.idTipoJuzgado,
            idTipoInstancia: tipoInstancia.idTipoInstancia,
            idMateria: materia.idMateria,
            idMunicipio: municipio.idMunicipio,
            idDistritoJudicial: distritoJudicial.idDistritoJudicial,
            idOficialia: oficialia ? oficialia.idOficialia : 0,
            idAplicacion: aplicacion.idAplicacion,
            descripcion,
            nombreCorto,
            activo,
            idOrigen
        };

        await ActualizarJuzgado( params );
    }

    const AgregarRegistro = () => {

        setTipoJuzgado( 0 );
        setTipoInstancia( 0 );
        setMateria( 0 );
        setMunicipio( 0 );
        setDistritoJudicial( 0 );
        setOficialia( 0 );
        setAplicacion( 0);      
        setDescripcion( '' );
        setNombreCorto( '' );
        setOrigen( '' );    

        setOpcion( 'agregar' );
        setIdReferencia( 0 );

        handleOpenModal();
    }

    const EditarRegistro = ( id, descripcion, idOrigen, idTipoJuzgado, idTipoInstancia, idMateria, idMunicipio, idDistritoJudicial, idOficialia, idAplicacion, nombreCorto ) => {
        
        setMateria( idMateria );
        setNombreCorto( nombreCorto );
        setDescripcion( descripcion );
        setOrigen( idOrigen );
        setDistritoJudicial( idDistritoJudicial );
        setMunicipio( idMunicipio );
        setTipoInstancia( idTipoInstancia );
        setTipoJuzgado( idTipoJuzgado );
        setOficialia( idOficialia );
        setAplicacion( idAplicacion );

        setOpcion( 'editar' );
        setIdReferencia( id );

        handleOpenModal();
    }

    const Guardar = async () => {

        const valid = validateFiels();

        if( !valid ){
            return false;
        }
        
        setLoadingSave( true );

        if( opcion === 'agregar' ){
            const params = {
                idJuzgado: 0,
                idOrigen: origen,
                descripcion: descripcion.trim(),
                nombreCorto: nombreCorto.trim(),
                idTipoJuzgado: tipoJuzgado,
                idTipoInstancia: tipoInstancia,
                idMateria: materia,
                idMunicipio: municipio,
                idDistritoJudicial: distritoJudicial,
                idOficialia: oficialia,
                idAplicacion: aplicacion,
                activo: true,
            };

            await AgregarJuzgado( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        if( response.data ){

                            setTimeout(() => {                                
                            
                                setRows([ 
                                    ...rows,  
                                    {
                                        idJuzgado: response.data.idJuzgado,
                                        idTipoJuzgado: response.data.idTipoJuzgado,
                                        idTipoInstancia: response.data.idTipoInstancia,
                                        idMateria: response.data.idMateria,
                                        idMunicipio: response.data.idMunicipio,
                                        idDistritoJudicial: response.data.idDistritoJudicial,
                                        idOficialia: response.data.idOficialia ?? '',
                                        idAplicacion: response.data.idAplicacion,

                                        tipoJuzgado: response.data.tipoJuzgado,
                                        tipoInstancia: response.data.tipoInstancia,
                                        materia: response.data.materia,
                                        municipio: response.data.municipio,
                                        distritoJudicial: response.data.distritoJudicial,
                                        oficialia: response.data.oficialia,
                                        aplicacion: response.data.aplicacion,

                                        idOrigen: origen,
                                        descripcion: descripcion,
                                        nombreCorto: nombreCorto,
                                        activo: true
                                    }
                                ]);  

                                handleCloseModal();

                                setLoadingSave( false );

                            }, 1000);
                        } 
                    }
                }
            });
        }
 
        if( opcion === 'editar' ){
            const juzgado = rows.filter( function(row){ return row.idJuzgado === idReferencia; } )[0];

            const params = {

                idJuzgado: idReferencia,
                idOrigen: origen,
                descripcion: descripcion.trim(),
                nombreCorto: nombreCorto.trim(),
                idTipoJuzgado: tipoJuzgado,
                idTipoInstancia: tipoInstancia,
                idMateria: materia,
                idMunicipio: municipio,
                idDistritoJudicial: distritoJudicial,
                idOficialia: oficialia,
                idAplicacion: aplicacion,
                activo: juzgado.activo,

            };
    
            await ActualizarJuzgado( params ).then( response => {
                if( response ){
                    if( response.status === 200){
                        
                        setTimeout(() => {
                            
                            setTimeout(() => {

                                const newRows = rows.map( (row) => { 
                                    if(row.idJuzgado === idReferencia){ 
                                        row.idOrigen = origen; 
                                        row.descripcion = descripcion; 
                                        row.nombreCorto = nombreCorto; 
                                        row.tipoJuzgado = response.data.tipoJuzgado;
                                        row.tipoInstancia = response.data.tipoInstancia;
                                        row.materia = response.data.materia;
                                        row.municipio = response.data.municipio;
                                        row.distritoJudicial = response.data.distritoJudicial;
                                        row.oficialia = response.data.oficialia;
                                        row.aplicacion = response.data.aplicacion;
                                        return row; 
                                    } 
                                    else{ 
                                        return row; 
                                    } 
                                });

                                setRows( newRows ); 
                                
                                handleCloseModal();

                                setLoadingSave( false );

                            }, 1000);

                        }, 1000);
                    }
                }                
            });
        }
    }

     const validateFiels = () => {
        
        setErrors( { } );

        let valid = true;
        let errores = {};

        if( descripcion.trim() === '' ){
            valid = false;
            errores.descripcion = 'Debes escribir la descripción';
        } 
        
        if( nombreCorto.trim() === '' ){
            valid = false;
            errores.nombreCorto = 'Debes escribir el nombre corto';
        } 
        
        if( origen === ''){
            valid = false;
            errores.origen = 'Debes escribir el id de origen';
        } 

        if( tipoJuzgado === 0 ){
            valid = false;
            errores.tipoJuzgado = 'Debes seleccionar un tipo de juzgado';
        }  

        if( tipoInstancia === 0 ){
            valid = false;
            errores.tipoInstancia = 'Debes seleccionar un tipo de instancia';
        }  

        if( materia === 0 ){
            valid = false;
            errores.materia = 'Debes seleccionar una materia';
        }  

        if( distritoJudicial === 0 ){
            valid = false;
            errores.distritoJudicial = 'Debes seleccionar un distrito judicial';
        }  

        if( aplicacion === 0 ){
            valid = false;
            errores.aplicacion = 'Debes seleccionar una aplicación';
        }  
        
        if( municipio === 0 ){
            valid = false;
            errores.municipio = 'Debes seleccionar un municipio';
        }  

        setErrors( errores );
        return valid;
    } 

    useEffect(() => {
        
        async function Obtener(){
            
            await Juzgados().then(response => {
                
                if( response ){

                    if( response.status === 200 ){

                        setLoadingTable( false );
                        setRows( response.data );
                    }

                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await TiposInstancias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoInstanciaArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await TipoJuzgado().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setTipoJuzgadoArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await Materias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setMateriaArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerDistritosJudiciales().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setDistritoJudicialArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerOficialias().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setOficialiaArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);


    useEffect(() => {
        
        async function Obtener(){
            
            await Aplicaciones().then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setAplicacionArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);



    useEffect(() => {
        
        async function Obtener(){
            
            await ObtenerMunicipios(5).then(response => {
                
                if( response ){
                    if( response.status === 200 ){
                        setMunicipioArray( response.data );
                    }
                }
                
            });

        }

        Obtener();

    }, [ ]);

    return (
        <>        

            <NavbarInicio />

            {
                ( !loading && tipoUsuario !== 3 )
                &&
                <NotFoundScreen />
            }

            {
                ( !loading && tipoUsuario === 3  )
                &&   
                <>

                    <Container maxWidth='xl' sx={{ mt: { md: '13em', xs: '11em' } }}>

                        <Box sx={{ pb: '2em', pl: { md: 10, xs: 1 }, pr: { md: 10, xs: 1 }, pt: 2 }} component={ Paper } >	

                            <Container maxWidth="lg">   

                                <Grid container spacing={3} sx={{ mt: 1 }} >  
                                        
                                    <Grid item md={6} xs={6} sx={{ textAlign: 'left' }}> 
                                        <Button type="button" startIcon={ <UndoIcon /> } variant='contained' color='inherit' sx={{ mt: 1 }} onClick={ () => navigate('/administracion/') }> 
                                            Regresar a los catálogos
                                        </Button> 
                                    </Grid>

                                    <Grid item md={6} xs={6} sx={{ textAlign: 'right' }}> 
                                        <Button type="button" startIcon={ <AddCircleOutlineRoundedIcon /> } variant="contained" sx={{ mt: 1 }} onClick={ AgregarRegistro }> 
                                            Agregar
                                        </Button> 
                                    </Grid>

                                </Grid>

                                <Grid container spacing={3} sx={{ mt: 1 }} >  
                                    
                                    <Grid item xs={12} md={12}> 

                                        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }} >
                                            <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div" >
                                                Juzgado
                                            </Typography>
                                        </Toolbar>

                                        <TableContainer>

                                            <Table sx={{ minWidth: 500 }} >
                                                <TableHead>
                                                    <TableRow>        
                                                        <TableCell style={{ width:'5%' }}> ID </TableCell>
                                                        <TableCell style={{ width:'5%' }}> ORIGEN </TableCell>
                                                        <TableCell style={{ width:'25%' }}> DESCRIPCIÓN </TableCell>
                                                        <TableCell style={{ width:'20%' }}> NOMBRE CORTO </TableCell>
                                                        <TableCell style={{ width:'10%' }}> TIPO DE JUZGADO </TableCell>
                                                        <TableCell style={{ width:'10%' }}> MATERIA </TableCell>
                                                        <TableCell style={{ width:'10%' }}> DISTRITO JUDICIAL </TableCell>
                                                                                                        
                                                        <TableCell style={{ width:'10%' }}> HABILITADO </TableCell>
                                                        <TableCell style={{ width:'10%' }}> EDITAR </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                {
                                                    (rowsPerPage > 0
                                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        : rows
                                                    ).map((row) => (
                                                        <TableRow key={ row.idJuzgado} >
                                                        
                                                            <TableCell style={{ fontWeight: 'bold' }}>
                                                                { row.idJuzgado }
                                                            </TableCell>

                                                            <TableCell>
                                                                { row.idOrigen }
                                                            </TableCell>

                                                            <TableCell>
                                                                { row.descripcion }
                                                            </TableCell>
                                                          
                                                            <TableCell>
                                                                { row.nombreCorto }
                                                            </TableCell>

                                                            <TableCell>
                                                                { row.tipoJuzgado.descripcion }
                                                            </TableCell>

                                                            <TableCell>
                                                                { row.materia.descripcion }
                                                            </TableCell>

                                                            <TableCell>
                                                                { row.distritoJudicial.descripcion }
                                                            </TableCell>

                                                            <TableCell>
                                                                {
                                                                    row.activo 
                                                                    ? 
                                                                    (
                                                                        <Switch 
                                                                            inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                            checked={ row.activo } 
                                                                            onChange={ ( e ) => handleChangeSwitch( row.idJuzgado, e.target.checked ) }
                                                                        />
                                                                    ) 
                                                                    :                                           
                                                                    ( 
                                                                        <Switch 
                                                                            inputProps={ { 'aria-label': 'Switch demo'} } 
                                                                            checked={ row.activo } 
                                                                            onChange={ ( e ) => handleChangeSwitch( row.idJuzgado, e.target.checked ) }
                                                                        />
                                                                    )
                                                                }
                                                            </TableCell>

                                                            <TableCell>
                                                                <IconButton 
                                                                        color="primary" 
                                                                        aria-label="edit" 
                                                                        component="span" 
                                                                        onClick={ () => EditarRegistro(                                                                 
                                                                            row.idJuzgado, row.descripcion, row.idOrigen, row.tipoJuzgado.idTipoJuzgado, row.tipoInstancia.idTipoInstancia, 
                                                                            row.materia.idMateria, row.municipio.idMunicipio, row.distritoJudicial.idDistritoJudicial, 
                                                                            row.oficialia ? row.oficialia.idOficialia : 0, row.aplicacion.idAplicacion, row.nombreCorto
                                                                        )} 
                                                                    >                                                        
                                                                    <EditIcon />
                                                                </IconButton>  
                                                            </TableCell>

                                                        </TableRow>
                                                    ))
                                                }

                                                { 
                                                    rows.length === 0 && (
                                                        <TableRow style={{ height: 53 }}>

                                                            <TableCell colSpan={9} align="center"> 
                                                                {
                                                                    loadingTable
                                                                    ?
                                                                        <CircularProgress size={40} sx={{ mt: 2, mb: 2 }} />
                                                                    :
                                                                        ''
                                                                }
                                                                {
                                                                    !loadingTable && rows.length === 0
                                                                    ?
                                                                        'No se encontraron resultados'
                                                                    :
                                                                        ''
                                                                }
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                }

                                                </TableBody>

                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[ ]}
                                                            colSpan={9}
                                                            count={rows.length}                                        
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}                                                                                
                                                            onPageChange={ handleChangePage }
                                                            onRowsPerPageChange={ handleChangeRowsPerPage }
                                                            ActionsComponent={ TablePaginationActions }

                                                            labelRowsPerPage="Filas por página"                                            
                                                            labelDisplayedRows={
                                                                ({ from, to, count }) => {
                                                                return '' + from + '-' + to + ' de ' + count
                                                                }
                                                            }  
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>

                                        </TableContainer>

                                    </Grid>

                                </Grid>

                            </Container>

                        </Box>

                    </Container>

                    <Dialog open={ openModal } maxWidth='md' fullWidth={true}>       
                    
                        <DialogContent>
                            
                            <Grid container spacing={3}>

                                <Grid item md={6} sm={12}>
                                    <FormControl fullWidth sx={{ mt: 3 }} >
                                        <InputLabel id="select-tipoJuzgado-label">Tipo de juzgado</InputLabel>
                                    
                                            <Select
                                                labelId="select-tipoJuzgado-label"
                                                id="select-tipoJuzgado"                                            
                                                label="Tipo Juzgado"
                                                name="tipoJuzgado"
                                                value={ tipoJuzgado }                                
                                                onChange={ ( e ) => { setTipoJuzgado( e.target.value ) } }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    tipoJuzgadoArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idTipoJuzgado } value={ elem.idTipoJuzgado }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                    </FormControl>       
                                            {   
                                                errors.tipoJuzgado && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoJuzgado } </Alert> )
                                            }
                                </Grid>

                                <Grid item md={6} sm={12}>

                                    <FormControl fullWidth sx={{ mt: 3 }} >
                                        <InputLabel id="select-tipoInstancia-label">Tipo de instancia</InputLabel>
                                    
                                            <Select
                                                labelId="select-tipoInstancia-label"
                                                id="select-tipoInstancia"                                            
                                                label="Tipo instancia"
                                                name="tipoInstancia"
                                                value={ tipoInstancia }                                
                                                onChange={ ( e ) => { setTipoInstancia( e.target.value ) } }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    tipoInstanciaArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idTipoInstancia } value={ elem.idTipoInstancia }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                    </FormControl>

                                            {   
                                                errors.tipoInstancia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.tipoInstancia } </Alert> )
                                            }

                                </Grid>

                            </Grid>
                            <Grid container spacing={3}>

                                <Grid item md={6} sm={12}>
                                    
                                    <FormControl fullWidth sx={{ mt: 3 }} >
                                        <InputLabel id="select-materia-label">Materia</InputLabel>
                                    
                                            <Select
                                                labelId="select-materia-label"
                                                id="select-materia"                                            
                                                label="Materia"
                                                name="materia"
                                                value={ materia }                                
                                                onChange={ ( e ) => { setMateria( e.target.value ) } }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    materiaArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idMateria } value={ elem.idMateria }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                    </FormControl>

                                            {   
                                                errors.materia && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.materia } </Alert> )
                                            }  
                                </Grid>
                                <Grid item md={6} sm={12}>
                                    
                                    <FormControl fullWidth sx={{ mt: 3 }} >
                                        <InputLabel id="select-distritoJudicial-label">Distrito judicial</InputLabel>
                                        
                                                <Select
                                                    labelId="select-distritoJudicial-label"
                                                    id="select-distritoJudicial"                                            
                                                    label="Distrito judicial"
                                                    name="distritoJudicial"
                                                    value={ distritoJudicial }                                
                                                    onChange={ ( e ) => { setDistritoJudicial( e.target.value ) } }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        distritoJudicialArray.map( ( elem ) => (
                                                            <MenuItem key={ elem.idDistritoJudicial } value={ elem.idDistritoJudicial }> { elem.descripcion } </MenuItem>
                                                        ))
                                                    }
                                                </Select>

                                        </FormControl>
                                            {   
                                                errors.distritoJudicial && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.distritoJudicial } </Alert> )
                                            }  
                                </Grid>
                            </Grid>
                                <Grid container spacing={3}>

                                    <Grid item md={6} sm={12}>
                                
                                        <FormControl fullWidth sx={{ mt: 3 }} >
                                            <InputLabel id="select-municipio-label">Municipio</InputLabel>
                                        
                                            <Select
                                                labelId="select-municipio-label"
                                                id="select-municipio"                                            
                                                label="Municipio"
                                                name="municipio"
                                                value={ municipio }                                
                                                onChange={ ( e ) => { setMunicipio( e.target.value ) } }
                                                //disabled={ estado === 0 }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    municipioArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idMunicipio } value={ elem.idMunicipio }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>

                                            {   
                                                errors.municipio && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.municipio } </Alert> )
                                            }          
                                    </Grid>
                                    <Grid item md={6} sm={12}>
                                
                                        <FormControl fullWidth sx={{ mt: 3 }} >
                                            <InputLabel id="select-oficialia-label">Oficialia</InputLabel>
                                        
                                            <Select
                                                labelId="select-oficialia-label"
                                                id="select-oficialia"                                            
                                                label="Oficialia"
                                                name="oficialia"
                                                value={ oficialia }                                
                                                onChange={ ( e ) => { setOficialia( e.target.value ) } }
                                                //disabled={ estado === 0 }
                                            >
                                                <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                {
                                                    oficialiaArray.map( ( elem ) => (
                                                        <MenuItem key={ elem.idOficialia } value={ elem.idOficialia }> { elem.descripcion } </MenuItem>
                                                    ))
                                                }
                                            </Select>

                                        </FormControl>
                                    </Grid>
                                        
                                </Grid>

                                        <FormControl fullWidth sx={{ mt: 3 }} >
                                            <InputLabel id="select-aplicacion-label">Aplicacion</InputLabel>
                                        
                                                <Select
                                                    labelId="select-aplicacion-label"
                                                    id="select-aplicacion"                                            
                                                    label="Aplicacion"
                                                    name="aplicacion"
                                                    value={ aplicacion }                                
                                                    onChange={ ( e ) => { setAplicacion( e.target.value ) } }
                                                >
                                                    <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                    {
                                                        aplicacionArray
                                                        .filter(( elem ) => elem.idAplicacion === 1 || elem.idAplicacion === 2 || elem.idAplicacion === 8 )
                                                        .map( ( elem ) => (
                                                            <MenuItem key={ elem.idAplicacion } value={ elem.idAplicacion }> { elem.descripcion } </MenuItem>
                                                        ))
                                                    }
                                                </Select>

                                        </FormControl>

                                            {   
                                                errors.aplicacion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.aplicacion } </Alert> )
                                            } 

                                                                
                                <TextField
                                    sx={{ mt: 3 }}
                                    label='Id origen'
                                    variant="outlined"                           
                                    multiline
                                    rows={1}                                        
                                    fullWidth
                                    value={ origen }
                                    onChange={ ( e ) => { setOrigen( e.target.value ) } }
                                />                       

                                {   
                                    errors.origen && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.origen  } </Alert> )
                                }
                                                                  
                                <TextField
                                    sx={{ mt: 3 }}
                                    label='Nombre corto'                                                             
                                    fullWidth
                                    value={ nombreCorto }
                                    onChange={ ( e ) => { setNombreCorto( e.target.value ) } }
                                />                       

                                {   
                                    errors.nombreCorto && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.nombreCorto  } </Alert> )
                                }

                                <TextField
                                    sx={{ mt: 3 }}
                                    label='Descripción'
                                    variant="outlined"                           
                                    multiline
                                    rows={4}                                        
                                    fullWidth
                                    value={ descripcion }
                                    onChange={ ( e ) => { setDescripcion( e.target.value ) } }
                                />

                                {   
                                    errors.descripcion && ( <Alert color='warning' severity="warning" variant="filled" style={{ marginTop: '1em' }} > { errors.descripcion } </Alert> )
                                }

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={ handleCloseModal }> Cancelar </Button>
                            <Button onClick={ Guardar } variant='contained' startIcon={ <SaveIcon /> } > Guardar </Button>
                        </DialogActions>

                        <LinearProgress sx={{ display: loadingSave ? 'flex' : 'none' }} />

                    </Dialog>
                </>

            }

        </>
    )
}
