import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { Alert, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, MobileStepper, OutlinedInput, Paper, Select, Snackbar, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { useTheme } from '@emotion/react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SendIcon from '@mui/icons-material/Send';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import CheckIcon from '@mui/icons-material/CheckCircle';
import UndoIcon from '@mui/icons-material/Undo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import { Navbar } from '../ui/Navbar';

import { FormatPhoneNumber } from '../../helpers/FormatPhoneNumber';

import { ObtenerTipoDomicilios } from '../../actions/catalogos/tipoDomicilioActions';
import { ObtenerAsentamientos, ObtenerCodigosPostales } from '../../actions/catalogos/asentamientoActions';
import { ObtenerMunicipios } from '../../actions/catalogos/municipioActions';
import { ObtenerEstados } from '../../actions/catalogos/estadoActions';
import { EnviarCodigoVerificacion, ReenviarCodigoVerificacion, RegistrarCuenta, VerificarCodigoRegistro } from '../../actions/sid/solicitudActions';

import { words } from '../../helpers/PalabrasClave';

const palabraClave = words[Math.floor(Math.random() * words.length)];

export const RegisterScreen = () => {

    const navigate = useNavigate();

    const theme = useTheme();

    const maxSteps = 6;

    const [activeStep, setActiveStep] = useState(0);

    const [loadingBack, setLoadingBack] = useState(false);
    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingReenviar, setLoadingReenviar] = useState(false);

    const [checkedAviso, setCheckedAviso] = useState(false)
    const [checkedTerminos, setCheckedTerminos] = useState(false)

    const [codigoVerficiacion, setCodigoVerficiacion] = useState('')

    const [nombre, setNombre] = useState('');
    const [apellido1, setApellido1] = useState('');
    const [apellido2, setApellido2] = useState('');
    const [curp, setCurp] = useState('');
    const [cedulaProfesional, setCedulaProfesional] = useState('');
    const [telefonoCelular, setTelefonoCelular] = useState('');
    const [telefonoFijo, setTelefonoFijo] = useState('');

    const [token, setToken] = useState('');
    const [i, setI] = useState(0);

    const [correoElectronico, setCorreoElectronico] = useState('');
    const [confirmarCorreoElectronico, setConfirmarCorreoElectronico] = useState('');
    const [contrasena, setContrasena] = useState('');
    const [confirmarContrasena, setConfirmarContrasena] = useState('');

    const [viewContrasena, setViewContrasena] = useState(false);
    const [viewConfirmarContrasena, setViewConfirmarContrasena] = useState(false);

    const [errores, setErrors] = useState({});
    const [openEnviarMensaje, setOpenEnviarMensaje] = useState(false);

    const [estado, setEstado] = useState(0);
    const [estadoArray, setEstadoArray] = useState([]);

    const [municipio, setMunicipio] = useState(0);
    const [municipioArray, setMunicipioArray] = useState([]);

    const [codigoPostal, setCodigoPostal] = useState('0');
    const [codigoPostalArray, setCodigoPostalArray] = useState([]);

    const [asentamiento, setAsentamiento] = useState(0);
    const [asentamientoArray, setAsentamientoArray] = useState([]);

    const [tipoDomicilio, setTipoDomicilio] = useState(0);
    const [tipoDomicilioArray, setTipoDomicilioArray] = useState([]);

    const [calle, setCalle] = useState('');
    const [numero, setNumero] = useState('');
    const [referencia, setReferencia] = useState('');

    const [modal, setModal] = useState(false);

    const [ine, setINE] = useState(null);
    const [docCURP, setDocCURP] = useState(null);
    const [cedula, setCedula] = useState(null);
    const [video, setVideo] = useState(null);

    const handleChangeViewContrasena = () => setViewContrasena((prev) => !prev);
    const handleChangeViewConfirmarContrasena = () => setViewConfirmarContrasena((prev) => !prev);

    const handleChangeINE = ({ meta, file }, status) => {
        if( status === "done" ) { 
            setINE( file );
        } 
        else if( status === "removed" ) {
            setINE( null );
        }
    }

    const handleChangeCURP = ({ meta, file }, status) => {
        if( status === "done" ) { 
            setDocCURP( file );
        } 
        else if( status === "removed" ) {
            setDocCURP( null );
        }
    }

    const handleChangeCedula = ({ meta, file }, status) => {
        if( status === "done" ) { 
            setCedula( file );
        } 
        else if( status === "removed" ) {
            setCedula( null );
        }
    }
    
    const handleChangeVideo = ({ meta, file }, status) => {
        switch(status) {
            case  "done":   setVideo( file );   break;
            case  "error_file_size":
            case  "removed":
            default:
                setVideo( null );   break;
        }
    }

    const validateFields = () => {

        let valid = true;
        let errores = {};

        const curpValida = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(curp.trim());
        const validCorreoElectronico = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(correoElectronico);
        const validConfirmarCorreoElectronico = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(confirmarCorreoElectronico);
        const validContrasena = /^[A-Z][A-Za-z0-9*.-]{7,15}$/.test(contrasena)
        const validconfirmarContrasena = /^[A-Z][A-Za-z0-9*.-]{7,15}$/.test(confirmarContrasena)
        const validStringNames = /^(?=.{3,40}$)[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ.]+(?:[\s][a-zA-ZáéíóúüñÁÉÍÓÚÜÑ.]+)*$/;
        const validStringNamesWithNumbersAndSymbols = /^(?=.{2,40}$)[a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ.#/]+(?:[\s][a-zA-Z0-9áéíóúüñÁÉÍÓÚÜÑ.#/]+)*$/;

        if (activeStep === 1) {

            if (!validStringNames.test(nombre.trim())) {
                valid = false
                errores.nombre = 'El Nombre debe contener al menos 3 letras.';
            }
            if (!validStringNames.test(apellido1.trim())) {
                valid = false
                errores.apellido1 = 'El Apellido debe contener al menos 3 letras.';
            }
            if (apellido2 !== '' && !validStringNames.test(apellido2.trim())) {
                valid = false
                errores.apellido2 = 'El Apellido debe contener al menos 3 letras.';
            }
            if (!curpValida) {
                valid = false
                errores.curp = 'La CURP es inválida';
            }

            if (telefonoCelular === '') {
                valid = false
                errores.telefonoCelular = 'Debe escribir el numero de celular';
            }
            else if (telefonoCelular.length !== 10) {
                valid = false;
                errores.telefonoCelular = 'El teléfono celular debe tener 10 digitos';
            }

            if (telefonoFijo.length !== 10 && telefonoFijo.length > 0) {
                valid = false;
                errores.telefonoFijo = 'El teléfono fijo debe tener 10 digitos';
            }
        }
        else if (activeStep === 2) {

            if (estado === 0) {
                valid = false
                errores.estado = 'Seleccione un estado';
            }

            if (municipio === 0) {
                valid = false
                errores.municipio = 'Seleccione un municipio';
            }

            if (codigoPostal === '0') {
                valid = false
                errores.codigoPostal = 'Seleccione un código Postal';
            }

            if (tipoDomicilio === 0) {
                valid = false
                errores.tipoDomicilio = 'Seleccione un tipo de domicilio';
            }
            if (asentamiento === 0) {
                valid = false
                errores.asentamiento = 'Seleccione un asentamiento';
            }

            if (!validStringNamesWithNumbersAndSymbols.test(calle)) {
                valid = false
                errores.calle = 'La Calle debe tener al menos 2 letras, puede incluir el símbolo #';
            }

            if (!validStringNamesWithNumbersAndSymbols.test(numero)) {
                valid = false
                errores.numero = 'El Número debe tener al menos 2 letras o números, puede incluir el símbolo #';
            }
        }
        else if (activeStep === 3) {

            if (correoElectronico === '') {
                valid = false
                errores.correoElectronico = 'Debe escribir el correo electrónico';
            }
            else if (!validCorreoElectronico) {
                valid = false
                errores.correoElectronico = 'El correo electrónico no es valido';
            }

            if (confirmarCorreoElectronico === '') {
                valid = false
                errores.confirmarCorreoElectronico = 'Debe confirmar el correo electrónico';
            }
            else if (!validConfirmarCorreoElectronico) {
                valid = false
                errores.confirmarCorreoElectronico = 'El correo electrónico no es valido';
            }
            else if (correoElectronico !== confirmarCorreoElectronico) {
                valid = false
                errores.confirmarCorreoElectronico = 'Los correos electrónicos no coinciden';
            }

            if (contrasena === '') {
                valid = false
                errores.contrasena = 'Debe escribir una Contraseña';
            }
            else if (!validContrasena) {
                valid = false
                errores.contrasena = 'La contraseña no es valida';
            }

            if (confirmarContrasena === '') {
                valid = false
                errores.confirmarContrasena = 'Debe confirmar la Contraseña';
            }
            else if (!validconfirmarContrasena) {
                valid = false
                errores.confirmarContrasena = 'La contraseña no es valida';
            }
            else if (confirmarContrasena !== contrasena) {
                valid = false
                errores.confirmarContrasena = 'Las contraseñas no coinciden';
            }

        }

        setErrors(errores);

        return valid;
    }

    const handleCloseModal = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setModal(false);
    };

    const handleNext = () => {

        const valid = validateFields();

        if (!valid) {
            return false;
        }

        setLoadingNext(true);

        setTimeout(() => {

            setLoadingNext(false);
            setActiveStep((prevStep) => prevStep + 1);

        }, 1200);
    }

    const handleBack = () => {

        setLoadingBack(true);

        setTimeout(() => {

            setLoadingBack(false);
            setActiveStep((prevStep) => prevStep - 1);

        }, 1200);

    }

    const handleValidarRegistro = async () => {

        const params = {
            token: token,
            Nip: codigoVerficiacion
        }

        setLoadingNext(true);

        await VerificarCodigoRegistro(params).then( async (response) => {

            if (response) {

                if (response.status === 200) {

                    const { success } = response.data;

                    if ( success ) {

                        await handleRegistrarUsuario();
                    } 
                    else {

                        setTimeout(() => {
                        
                            setModal( true );
                            setLoadingNext( false );

                        }, 1000);
                        
                    }
                }
            }

        });

    }

    const handleRegistrarUsuario = async () => {

        let fecha;
        let sexo;

        if (curp.substring(4, 6) > 22) {
            fecha = "19" + curp.substring(4, 6) + "-" + curp.substring(6, 8) + "-" + curp.substring(8, 10);
        }
        else {
            fecha = "20" + curp.substring(4, 6) + "-" + curp.substring(6, 8) + "-" + curp.substring(8, 10);
        }

        if (curp.substring(10, 11) === "M") {
            sexo = 1;
        }
        else {
            sexo = 2;
        }

        let formData = new FormData();
		    formData.append('nombre', nombre.trim());  
            formData.append('apellido1', apellido1.trim());  
            formData.append('apellido2', apellido2.trim());  
            formData.append('curp', curp);  
            formData.append('cedulaProfesional', cedulaProfesional.trim());  
            formData.append('telefonoCelular', FormatPhoneNumber(telefonoCelular));  
            formData.append('telefonoFijo', FormatPhoneNumber(telefonoFijo));  
            formData.append('correoElectronico', correoElectronico);  
            formData.append('contrasena', contrasena);  
            formData.append('idAsentamiento', asentamiento);  
            formData.append('idTipoDomicilio', tipoDomicilio);  
            formData.append('calleVialidad', calle);  
            formData.append('numero', numero);  
            formData.append('referencia', referencia);  
            formData.append('palabraClave', palabraClave);  
            formData.append('idSexo', sexo);  
            formData.append('fechaNacimiento', fecha);  
            formData.append('token', token);  
            formData.append('documento_ine', ine);  
            formData.append('documento_curp', docCURP);  
            formData.append('documento_cedula', cedula);  
            formData.append('video', video);  

        await RegistrarCuenta( formData ).then(response => {

            if (response) {

                if (response.status === 200) {

                    setTimeout(() => {

                        setLoadingNext(false);
                        setActiveStep((prevStep) => prevStep + 1);

                    }, 1000);

                }
                else{
                    setTimeout(() => {

                        setLoadingNext(false);

                    }, 1000);
                }

            }

        });        

    }

    const reenviarMensaje = async () => {

        setLoadingReenviar( true );

        const params = {
            token: token,
            telefonoCelular: telefonoCelular,
            usuario: ( nombre.trim() + ' ' + apellido1.trim() +  ' ' + apellido2.trim() ).trim(),            
        }

        await ReenviarCodigoVerificacion(params).then(response => {

            if (response) {

                if (response.status === 200) {

                    setI( 45 );
                    setLoadingReenviar( false );
                }

            }

        });

    }

    const handleEnviarCodigo = async () => {

        setLoadingNext( true );

        const params = {
            correoElectronico: correoElectronico,
            telefonoCelular: telefonoCelular,
            usuario: ( nombre.trim() + ' ' + apellido1.trim() +  ' ' + apellido2.trim() ).trim(),            
        }

        await EnviarCodigoVerificacion(params).then(response => {

            if (response) {

                if (response.status === 200) {

                    setTimeout(() => {

                        setToken(response.data.token);

                        setLoadingNext(false);
                        setActiveStep((prevStep) => prevStep + 1);
            
                    }, 1200);

                }

            }

        });

    }

    useEffect(() => {
        
        if( i > 0 ){
            
            setTimeout(() => {
                
                setI( i - 1 );

            }, 1000);

        }
     
    }, [ i ])
    
    useEffect(() => {

        async function Obtener() {

            await ObtenerEstados().then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {
                            setEstadoArray(response.data);
                        }

                    }

                }

            });

        }

        Obtener();

    }, [])

    useEffect(() => {

        async function Obtener() {

            setMunicipio(0);

            await ObtenerMunicipios(estado).then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {
                            setMunicipioArray(response.data);
                        }

                    }

                }

            });
        }

        Obtener();

    }, [estado])

    useEffect(() => {

        async function Obtener() {

            setCodigoPostal('0')

            await ObtenerCodigosPostales(municipio).then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {
                            setCodigoPostalArray(response.data);
                        }

                    }

                }

            });
        }

        Obtener();

    }, [municipio])

    useEffect(() => {

        async function Obtener() {

            setAsentamiento(0)

            const param = {
                codigoPostal: codigoPostal,
                idMunicipio: municipio,
            };

            await ObtenerAsentamientos(param).then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {
                            setAsentamientoArray(response.data);
                        }

                    }

                }

            });
        }

        Obtener();

    }, [municipio, codigoPostal])

    useEffect(() => {

        async function Obtener() {

            setTipoDomicilio(0);

            await ObtenerTipoDomicilios().then(response => {

                if (response) {

                    if (response.status === 200) {

                        if (response.data) {
                            setTipoDomicilioArray(response.data);

                        }

                    }

                }
            });

        }

        Obtener();

    }, [])

    return (
        <>

            <Navbar />

            <Snackbar open={modal} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={2000} onClose={handleCloseModal}>
                <Alert onClose={handleCloseModal} variant="filled" severity='error' sx={{ width: '100%' }}>
                    El código de verificacion no es valido
                </Alert>
            </Snackbar>

            <Container maxWidth='xl' sx={{ mt: '10em' }}>

                <Box sx={{ pb: '2em' }} component={Paper} >

                    <Grid container spacing={3} >

                        <Grid item md={12} xs={12} sx={{ textAlign: 'right', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '1em' } }}>
                            <Button startIcon={<UndoIcon />} onClick={() => navigate('/')} >
                                Regresar al inicio
                            </Button>
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '1em' }, pl: { xs: '1em', md: '1em' }, display: { xs: 'none', md: 'block' } }} >

                        <Grid item md={12} xs={12} >

                            <Stepper activeStep={activeStep} alternativeLabel >
                                <Step>
                                    <StepLabel>Indicaciones iniciales</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Registrar información personal</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Registrar información del domicilio</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Registrar datos de la cuenta</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Añadir documentos y prueba de vida</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Validar código de verificación</StepLabel>
                                </Step>

                            </Stepper>

                        </Grid>

                    </Grid>

                    {
                        activeStep === 0 ? (
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'justify' }}>

                                        <Typography variant='h6'>
                                            Antes de iniciar su solicitud para registrarse en la Plataforma Poder en Línea favor de considerar lo siguiente:
                                        </Typography>

                                        <Typography variant='body2' sx={{ mt: 5 }}>

                                            <strong>1.</strong> Deberá tener disponible en un documento .pdf, .png o .jpg su Identificación oficial con fotografía vigente (Credencial de elector por ambos lados , Licencia de Conducir por ambos lados o Pasaporte) con un tamaño no mayor a 4 MB.
                                            <br /> <br />

                                            <strong>2.</strong> Deberá tener disponible su Constancia de la Clave Única de Registro de Población (CURP) expedida por la Secretaría de Gobernación (<strong><a href='https://www.gob.mx/curp' target='_blank' rel='noopener noreferrer' style={{ color: '#252B50' }}>https://www.gob.mx/curp</a></strong>).
                                            <br /> <br />

                                            <strong>3.</strong> Deberá tener disponible un medio para grabación de audio y video (teléfono celular, webcam o videograbadora) que soporte los siguientes formatos .mov, .avi, .mp4, .mpeg, .m4v o .wmv. El video no deberá ser mayor a 30 MB
                                            <br /> <br />

                                            <strong>4.</strong> Deberá tener disponible un número celular el cual registrará para el envío de nips de firmado electrónico.

                                        </Typography>

                                        <Typography variant='body2' sx={{ mt: 5 }}>

                                            <strong>Opcional</strong>
                                            <br /> <br />

                                            Tener disponible en un documento .pdf, .png o .jpg su Cédula Profesional por ambos lados con un tamaño no mayor a 4 MB.

                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'right', display: { xs: 'none', md: 'block' } }}>

                                        <LoadingButton
                                            variant="contained"
                                            onClick={handleNext}
                                            endIcon={<ArrowForwardIcon />}
                                            loading={loadingNext}
                                        >
                                            Iniciar
                                        </LoadingButton>

                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 1 ? (
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Registrar información personal
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <Grid container spacing={3} sx={{ pr: { md: '3em' }, pl: { md: '3em' } }} >

                                            <Grid item md={4} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Nombres(s)"
                                                    name="nombres"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    value={nombre}
                                                    onChange={(e) => { setNombre(e.target.value.toUpperCase()) }}
                                                    required
                                                />
                                                {
                                                    errores.nombre
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.nombre}
                                                    </Alert>

                                                }

                                            </Grid>
                                            <Grid item md={4} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Apellido 1"
                                                    name="apellido1"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    value={apellido1}
                                                    onChange={(e) => { setApellido1(e.target.value.toUpperCase()) }}
                                                    required
                                                />
                                                {
                                                    errores.apellido1
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.apellido1}
                                                    </Alert>

                                                }

                                            </Grid>

                                            <Grid item md={4} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Apellido 2"
                                                    name="apellido2"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    value={apellido2}
                                                    onChange={(e) => { setApellido2(e.target.value.toUpperCase()) }}
                                                />
                                                {
                                                    errores.apellido2
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.apellido2}
                                                    </Alert>

                                                }

                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="CURP"
                                                    name="curp"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    value={curp}
                                                    onChange={(e) => { setCurp(e.target.value.toUpperCase()) }}
                                                    inputProps={{ maxLength: 18 }}
                                                    required
                                                />
                                                {
                                                    errores.curp
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.curp}
                                                    </Alert>
                                                }

                                            </Grid>
                                            <Grid item md={6} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Cédula Profesional"
                                                    name="cedulaProfesional"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    type='number'
                                                    value={cedulaProfesional}
                                                    onChange={(e) => { setCedulaProfesional(e.target.value) }}
                                                />

                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Teléfono Celular"
                                                    name="telefonoCelular"
                                                    variant="outlined"
                                                    value={telefonoCelular}
                                                    type='number'
                                                    autoComplete='off'
                                                    onChange={(e) => { setTelefonoCelular(e.target.value) }}
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }}
                                                    required
                                                />
                                                {
                                                    errores.telefonoCelular
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.telefonoCelular}
                                                    </Alert>
                                                }

                                            </Grid>

                                            <Grid item md={6} xs={12}  >

                                                <TextField
                                                    fullWidth
                                                    label="Teléfono Fijo"
                                                    name="telefonoFijo"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    type='number'
                                                    value={telefonoFijo}
                                                    onChange={(e) => { setTelefonoFijo(e.target.value) }}
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }}
                                                />
                                                {
                                                    errores.telefonoFijo
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.telefonoFijo}
                                                    </Alert>
                                                }
                                            </Grid>


                                        </Grid>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>

                                                <LoadingButton
                                                    variant="text"
                                                    color="inherit"
                                                    onClick={handleBack}
                                                    startIcon={<ArrowBackIcon />}
                                                    loading={loadingBack}
                                                >
                                                    Regresar
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>

                                                <LoadingButton
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    endIcon={<ArrowForwardIcon />}
                                                    loading={loadingNext}
                                                >
                                                    Siguiente
                                                </LoadingButton>

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 2 ? (
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Registrar información del domicilio
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <Grid container spacing={3} sx={{ pr: { md: '3em' }, pl: { md: '3em' } }} >

                                            <Grid item md={6} xs={12} >

                                                <FormControl fullWidth>

                                                    <InputLabel id="select-estado-label">Estado</InputLabel>

                                                    <Select
                                                        labelId="select-estado-label"
                                                        id="select-estado"
                                                        label="estado"
                                                        name="estado"
                                                        value={estado}
                                                        onChange={(e) => setEstado(e.target.value)}
                                                    >
                                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                        {
                                                            estadoArray.map((element) => (
                                                                <MenuItem key={element.idEstado} value={element.idEstado}> {element.descripcion} </MenuItem>
                                                            ))
                                                        }
                                                    </Select>

                                                </FormControl>

                                                {
                                                    errores.estado
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.estado}
                                                    </Alert>
                                                }
                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <FormControl fullWidth>

                                                    <InputLabel id="select-municipio-label">Municipio</InputLabel>

                                                    <Select
                                                        labelId="select-municipio-label"
                                                        id="select-municipio"
                                                        label="municipio"
                                                        name="municipio"
                                                        value={municipio}
                                                        onChange={(e) => setMunicipio(e.target.value)}
                                                        disabled={estado === 0}
                                                    >
                                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                        {
                                                            municipioArray.map((element) => (
                                                                <MenuItem key={element.idMunicipio} value={element.idMunicipio} > {element.descripcion} </MenuItem>
                                                            ))
                                                        }
                                                    </Select>

                                                </FormControl>

                                                {
                                                    errores.municipio
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.municipio}
                                                    </Alert>
                                                }
                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <FormControl fullWidth>

                                                    <InputLabel id="select-codigoPostal-label">Código Postal</InputLabel>

                                                    <Select
                                                        labelId="select-codigoPostal-label"
                                                        id="select-codigoPostal"
                                                        label="codigoPostal"
                                                        name="codigoPostal"
                                                        value={codigoPostal}
                                                        onChange={(e) => setCodigoPostal(e.target.value)}
                                                        disabled={municipio === 0}
                                                    >
                                                        <MenuItem key={'0'} value={'0'}>Seleccione una opción</MenuItem>
                                                        {
                                                            codigoPostalArray.map((element) => (
                                                                <MenuItem key={element.codigoPostal} value={element.codigoPostal} > {element.codigoPostal} </MenuItem>
                                                            ))
                                                        }
                                                    </Select>

                                                </FormControl>

                                                {
                                                    errores.codigoPostal
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.codigoPostal}
                                                    </Alert>
                                                }
                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <FormControl fullWidth>

                                                    <InputLabel id="select-asentamiento-label">Asentamiento</InputLabel>

                                                    <Select
                                                        labelId="select-asentamiento-label"
                                                        id="select-asentamiento"
                                                        label="asentamiento"
                                                        name="asentamiento"
                                                        value={asentamiento}
                                                        onChange={(e) => setAsentamiento(e.target.value)}
                                                        disabled={codigoPostal === '0'}
                                                    >
                                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                        {
                                                            asentamientoArray.map((element) => (
                                                                <MenuItem key={element.idAsentamiento} value={element.idAsentamiento} > {element.descripcion} </MenuItem>
                                                            ))
                                                        }
                                                    </Select>

                                                </FormControl>

                                                {
                                                    errores.asentamiento
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.asentamiento}
                                                    </Alert>
                                                }
                                            </Grid>

                                            <Grid item md={4} xs={12} >

                                                <FormControl fullWidth>

                                                    <InputLabel id="select-tipoDomicilio-label">Tipo de Domicilio</InputLabel>

                                                    <Select
                                                        labelId="select-tipoDomicilio-label"
                                                        id="select-tipoDomicilio"
                                                        label="Tipo de Domicilio"
                                                        name="tipoDomicilio"
                                                        value={tipoDomicilio}
                                                        onChange={(e) => setTipoDomicilio(e.target.value)}


                                                    >
                                                        <MenuItem key={0} value={0}>Seleccione una opción</MenuItem>
                                                        {
                                                            tipoDomicilioArray.map((element) => (
                                                                <MenuItem key={element.idTipoDomicilio} value={element.idTipoDomicilio} > {element.descripcion} </MenuItem>
                                                            ))
                                                        }
                                                    </Select>

                                                </FormControl>

                                                {
                                                    errores.tipoDomicilio
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.tipoDomicilio}
                                                    </Alert>
                                                }
                                            </Grid>

                                            <Grid item md={4} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Calle o Vialidad"
                                                    name="calleVialidad"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    value={calle}
                                                    onChange={(e) => setCalle(e.target.value)}
                                                    required
                                                />

                                                {
                                                    errores.calle
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.calle}
                                                    </Alert>

                                                }

                                            </Grid>
                                            <Grid item md={4} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Número Int./ext."
                                                    name="numero"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    required
                                                    value={numero}
                                                    onChange={(e) => setNumero(e.target.value)}
                                                />

                                                {
                                                    errores.numero
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.numero}
                                                    </Alert>

                                                }
                                            </Grid>

                                            <Grid item md={12} xs={12} >

                                                <TextField
                                                    multiline
                                                    rows={5}
                                                    fullWidth
                                                    label="Referencia"
                                                    name="referencia"
                                                    variant="outlined"
                                                    autoComplete='off'
                                                    value={referencia}
                                                    onChange={(e) => setReferencia(e.target.value)}
                                                />

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>

                                                <LoadingButton
                                                    variant="text"
                                                    color="inherit"
                                                    onClick={handleBack}
                                                    startIcon={<ArrowBackIcon />}
                                                    loading={loadingBack}
                                                >
                                                    Regresar
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>

                                                <LoadingButton
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    endIcon={<ArrowForwardIcon />}
                                                    loading={loadingNext}
                                                >
                                                    Siguiente
                                                </LoadingButton>

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 3 ? (
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Registrar información de la cuenta
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <Grid container spacing={3} sx={{ pr: { md: '3em' }, pl: { md: '3em' } }} >

                                            <Grid item md={6} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Correo Electrónico"
                                                    name="correoElectronico"
                                                    variant="outlined"
                                                    type='email'
                                                    autoComplete='off'
                                                    value={correoElectronico}
                                                    onChange={(e) => { setCorreoElectronico(e.target.value) }}
                                                    required
                                                />
                                                {
                                                    errores.correoElectronico
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.correoElectronico}
                                                    </Alert>

                                                }
                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <TextField
                                                    fullWidth
                                                    label="Confirmar Correo Electrónico"
                                                    name="confirmarCorreoElectronico"
                                                    variant="outlined"
                                                    type='email'
                                                    autoComplete='off'
                                                    value={confirmarCorreoElectronico}
                                                    onChange={(e) => { setConfirmarCorreoElectronico(e.target.value) }}
                                                    required
                                                />
                                                {
                                                    errores.confirmarCorreoElectronico
                                                    &&
                                                    <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                        {errores.confirmarCorreoElectronico}
                                                    </Alert>

                                                }

                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel>Contraseña</InputLabel>
                                                    <OutlinedInput
                                                        label="Contraseña"
                                                        name="contrasena"
                                                        type={viewContrasena ? 'text' : 'password'}
                                                        value={contrasena}
                                                        autoComplete='off'
                                                        onChange={(e) => { setContrasena(e.target.value) }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleChangeViewContrasena} edge="end" >
                                                                    {
                                                                        viewContrasena
                                                                            ?
                                                                            <VisibilityOffIcon />
                                                                            :
                                                                            <VisibilityIcon />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />

                                                    {
                                                        errores.contrasena
                                                        &&
                                                        <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                            {errores.contrasena}
                                                        </Alert>

                                                    }
                                                </FormControl>
                                            </Grid>

                                            <Grid item md={6} xs={12} >

                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel>Confirmar contraseña</InputLabel>
                                                    <OutlinedInput
                                                        label="Confirmar contraseña"
                                                        name="confirmarContrasena"
                                                        type={viewConfirmarContrasena ? 'text' : 'password'}
                                                        value={confirmarContrasena}
                                                        autoComplete='off'
                                                        onChange={(e) => { setConfirmarContrasena(e.target.value) }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton onClick={handleChangeViewConfirmarContrasena} edge="end" >
                                                                    {
                                                                        viewConfirmarContrasena
                                                                            ?
                                                                            <VisibilityOffIcon />
                                                                            :
                                                                            <VisibilityIcon />
                                                                    }
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                    {
                                                        errores.confirmarContrasena
                                                        &&
                                                        <Alert severity='warning' variant='filled' sx={{ mt: 1 }}>
                                                            {errores.confirmarContrasena}
                                                        </Alert>

                                                    }
                                                </FormControl>

                                            </Grid>

                                            <Grid item md={12} xs={12} >

                                                <Typography variant='body2'>

                                                    <strong>Esta contraseña sera para uso en la Plataforma Poder en Línea</strong>
                                                    <br /> <br />

                                                    <strong>La Contraseña debe contener:</strong>
                                                    <br /> <br />

                                                    <strong>1.</strong> Debe estar formada de 8 a 16 caracteres. <br />
                                                    <strong>2.</strong> Debe contener numeros y letras. <br />
                                                    <strong>3.</strong> Al menos Primer caracter debe ser una letra y estar en Mayuscula. <br />
                                                    <strong>4.</strong> Los caracteres "*", "-", y "."" estan permitidos. <br />

                                                </Typography>

                                            </Grid>


                                        </Grid>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>

                                                <LoadingButton
                                                    variant="text"
                                                    color="inherit"
                                                    onClick={handleBack}
                                                    startIcon={<ArrowBackIcon />}
                                                    loading={loadingBack}
                                                >
                                                    Regresar
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>

                                                <LoadingButton
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    endIcon={<ArrowForwardIcon />}
                                                    loading={loadingNext}
                                                >
                                                    Siguiente
                                                </LoadingButton>

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 4 ? (
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >

                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Añadir documentos y prueba de vida
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <Grid container spacing={3} sx={{ pr: { md: '3em' }, pl: { md: '3em' } }} >

                                            <Grid item md={12} xs={12} >

                                                <Typography variant='body2' sx={{ fontWeight: 'bold', fontSize: 18 }}>
                                                    Documentos para validación de identidad
                                                </Typography>

                                                <Typography variant='body2' sx={{ mt: 1 }}>
                                                    Un sólo archivo por cada apartado en formato .pdf, .jpg o .png (Tamaño máximo permitido 4 MB)
                                                </Typography>

                                            </Grid>

                                            <Grid item md={4} xs={12} >

                                                <Dropzone
                                                    onChangeStatus={ handleChangeINE }
                                                    accept="image/*,.pdf"
                                                    inputContent={<Typography key={0} variant='body2' sx={{ fontWeight: 'bold' }}> Identificación oficial con fotografía vigente (Credencial de elector, Licencia de Conducir o Pasaporte) </Typography>}
                                                    maxFiles={1}
                                                    SubmitButtonComponent={null}
                                                    styles={{
                                                        dropzone: { border: '1px dashed #252B50' },
                                                        inputLabel: { color: '#252B50', fontSize: 14, textAlign: 'center', padding: '5px' }
                                                    }}
                                                />

                                            </Grid>

                                            <Grid item md={4} xs={12} >

                                                <Dropzone
                                                    onChangeStatus={handleChangeCURP}
                                                    accept="image/*,.pdf"
                                                    inputContent={<Typography key={0} variant='body2' sx={{ fontWeight: 'bold' }}> Constancia de la Clave Única de Registro de Población (CURP) </Typography>}
                                                    maxFiles={1}
                                                    SubmitButtonComponent={null}
                                                    styles={{
                                                        dropzone: { border: '1px dashed #252B50' },
                                                        inputLabel: { color: '#252B50', fontSize: 14, textAlign: 'center', padding: '5px' }
                                                    }}
                                                />

                                            </Grid>

                                            <Grid item md={4} xs={12} >

                                                <Dropzone
                                                    onChangeStatus={handleChangeCedula}
                                                    accept="image/*,.pdf"
                                                    inputContent={<Typography key={0} variant='body2' sx={{ fontWeight: 'bold' }}> Cédula profesional (Opcional) </Typography>}
                                                    maxFiles={1}
                                                    SubmitButtonComponent={null}
                                                    styles={{
                                                        dropzone: { border: '1px dashed #252B50' },
                                                        inputLabel: { color: '#252B50', fontSize: 14, textAlign: 'center', padding: '5px' }
                                                    }}
                                                />

                                            </Grid>

                                            <Grid item md={12} xs={12} >

                                                <Typography variant='body2' sx={{ fontWeight: 'bold', fontSize: 18 }}>
                                                    Prueba de vida
                                                </Typography>

                                                <Typography variant='body2' sx={{ mt: 1 }}>
                                                    Un sólo archivo de video en formato <strong>.mov, .avi, .mp4, .mpeg, .wmv (Tamaño máximo permitido 30 MB)</strong>
                                                </Typography>

                                            </Grid>

                                            <Grid item md={12} xs={12} >

                                                <Dropzone
                                                    onChangeStatus={handleChangeVideo}
                                                    accept="video/*"
                                                    maxFiles={1}
                                                    inputContent={
                                                        <Typography key={0} variant='body2'>
                                                            <strong>Registre con claridad en audio y video la siguiente declaración:</strong>

                                                            <br /> <br />
                                                            Mi nombre es {nombre} {apellido1} {apellido2} 
                                                            esta es una prueba de vida donde solicito se me de acceso a la Plataforma Poder en Línea.
                                                            La palabra clave es: <strong>{palabraClave}</strong>
                                                        </Typography>
                                                    }
                                                    SubmitButtonComponent={null}
                                                    maxSizeBytes={30000000}
                                                    styles={{
                                                        dropzone: { border: '1px dashed #252B50', height: 200 },
                                                        inputLabel: { color: '#252B50', fontSize: 14, padding: '15px' }
                                                    }}
                                                />

                                            </Grid>

                                            <Grid item md={12} xs={12} >

                                                <FormGroup>

                                                    <FormControlLabel control={ <Checkbox size='small' onChange={() => setCheckedAviso(!checkedAviso)} /> } label={
                                                        <Typography variant='body2'>
                                                            <Link to='/aviso/' target='_blank' style={{ color: '#252B50' }}> Aceptar aviso de privacidad </Link>
                                                        </Typography>
                                                    } />

                                                    <FormControlLabel control={<Checkbox size='small' onChange={() => setCheckedTerminos(!checkedTerminos)} />} label={
                                                        <Typography variant='body2'>
                                                            <Link to='/terminos/' target='_blank' style={{ color: '#252B50' }}> Aceptar términos y condiciones de uso </Link>
                                                        </Typography>
                                                    } />

                                                </FormGroup>

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>

                                                <LoadingButton
                                                    variant="text"
                                                    color="inherit"
                                                    onClick={handleBack}
                                                    startIcon={<ArrowBackIcon />}
                                                    loading={loadingBack}
                                                >
                                                    Regresar
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>

                                                <LoadingButton
                                                    variant="contained"
                                                    onClick={() => { setOpenEnviarMensaje(true); }}
                                                    endIcon={<ArrowForwardIcon />}
                                                    loading={loadingNext}
                                                    disabled={ 
                                                        !checkedAviso || 
                                                        !checkedTerminos || 
                                                        ine === null || 
                                                        docCURP === null ||
                                                        video === null
                                                    }
                                                >
                                                    Siguiente
                                                </LoadingButton>

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </>
                        ) :
                        activeStep === 5 ? (
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >
                                   
                                    <Grid item md={12} xs={12} sx={{ display: { xs: 'flex', md: 'none' }, textAlign: 'center' }} >

                                        <Typography variant='h6'>
                                            Validar código de verificación
                                        </Typography>

                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <Grid container spacing={3} sx={{ pr: { md: '3em' }, pl: { md: '3em' } }} display='flex' flexDirection='column' alignItems='center' >

                                            <Grid item md={12} xs={12}>

                                                <Typography variant='h6'>Ingrese el codigo de verificación</Typography>

                                            </Grid>

                                            <Grid item md={3} xs={12}>

                                                <TextField
                                                    name='codigoVerificacion'
                                                    label=''
                                                    type='text'
                                                    autoComplete='off'
                                                    value={codigoVerficiacion}
                                                    onChange={(e) => { setCodigoVerficiacion(e.target.value) }}
                                                    inputProps={{ style: { textAlign: 'center', fontSize: '1.5em' } }}
                                                />

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                    <Grid item md={12} xs={12} sx={{ mt: 1, display: { xs: 'none', md: 'block' } }}>

                                        <Grid container spacing={3}>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'left' }}>

                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={ reenviarMensaje }
                                                    startIcon={ <SendIcon /> }
                                                    disabled={ i !== 0 }
                                                    loading={ loadingReenviar }
                                                >
                                                    {
                                                        (i !== 0 )
                                                        ?
                                                            `Reintentar en ${ i } `
                                                        :
                                                            'Reenviar código'
                                                    }
                                                </LoadingButton>

                                            </Grid>

                                            <Grid item md={6} xs={6} style={{ textAlign: 'right' }}>

                                                <LoadingButton
                                                    variant="contained"
                                                    color='success'
                                                    onClick={ handleValidarRegistro }
                                                    endIcon={<HowToRegIcon />}
                                                    loading={loadingNext}
                                                >
                                                    Registrarse
                                                </LoadingButton>

                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </>
                        ) :
                            <>
                                <Grid container spacing={3} sx={{ mt: '1em', pr: { xs: '1em', md: '2em' }, pl: { xs: '1em', md: '2em' } }} >

                                    <Grid item md={12} xs={12} sx={{ textAlign: 'center' }}>
                                        <CheckIcon sx={{ fontSize: '7em' }} color='success' />
                                    </Grid>

                                    <Grid item md={12} xs={12}>

                                        <Typography variant='body2' sx={{ textAlign: 'center', fontSize: 18 }}>
                                            <strong>Información registrada correctamente.</strong> <br /> <br />
                                            La solicitud fue enviada para su autorización, <br /> en un lapso de 24 horas recibirá una notificación de su proceso de registro.
                                        </Typography>

                                    </Grid>

                                </Grid>

                            </>
                    }

                    {
                        activeStep !== 6
                        &&
                        <Grid container spacing={3} sx={{ mt: '1em', pr: '1em', pl: '2em', display: { xs: 'block', md: 'none' } }} >

                            <MobileStepper
                                variant={null}
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <LoadingButton
                                        variant='contained'
                                        size="small"
                                        onClick={
                                            () => (activeStep !== 4 && activeStep !== 5)
                                                ?
                                                handleNext()
                                                :
                                                activeStep === 5
                                                    ?
                                                    handleValidarRegistro()
                                                    :
                                                    setOpenEnviarMensaje( true )
                                        }

                                        disabled={ (activeStep === maxSteps) || ( activeStep === 4 ? ( !checkedAviso || !checkedTerminos ) : false ) }
                                        loading={loadingNext}
                                        color={activeStep === 5 ? 'success' : 'primary'}
                                    >
                                        {activeStep === 0 ? 'Iniciar' : ''}
                                        {activeStep === 5 ? 'Registrarse' : ''}
                                        {activeStep !== 0 && activeStep !== 5 ? 'Siguiente' : ''}

                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeftIcon />
                                        ) : (
                                            activeStep === 5 ? <HowToRegIcon fontSize="small" /> : <KeyboardArrowRightIcon />
                                        )}
                                    </LoadingButton>
                                }
                                backButton={
                                    <LoadingButton
                                        variant={activeStep === 5 ? 'contained' : 'text'}
                                        size="small"
                                        onClick={() => activeStep === 5 ? reenviarMensaje() : handleBack()}
                                        sx={{ display: activeStep === 0 ? 'none' : 'flex' }}
                                        loading={loadingBack || loadingReenviar }
                                        disabled={ i !== 0 }
                                    >
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRightIcon />
                                        ) : (
                                            activeStep === 5 ? <SendIcon fontSize="small" /> : <KeyboardArrowLeftIcon />
                                        )}
                                        
                                        {
                                            activeStep === 5 
                                            ? 
                                                (i !== 0 )
                                                ?
                                                    `Reintentar en ${ i } `
                                                :
                                                    'Reenviar código'
                                            : ''
                                        }

                                        {activeStep !== 5 ? 'Regresar' : ''}

                                    </LoadingButton>
                                }
                            />

                        </Grid>
                    }

                </Box>

            </Container>

            <Dialog open={openEnviarMensaje} onClose={() => { }} >

                <DialogTitle>
                    Aviso
                </DialogTitle>

                <DialogContent>

                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)', fontWeight: 'normal' }}>
                        Se enviará un código de verificación al número celular:
                    </DialogContentText>

                    <DialogContentText sx={{ color: 'rgba(0, 0, 0, 1)', fontSize: 18, textAlign: 'center', mt: 2 }}>
                        {FormatPhoneNumber(telefonoCelular)}
                    </DialogContentText>

                </DialogContent>

                <DialogActions>
                    <Button onClick={() => { setOpenEnviarMensaje(false); }}> Cancelar </Button>
                    <Button onClick={() => { setOpenEnviarMensaje(false); handleEnviarCodigo(); }} variant='contained' > Aceptar </Button>
                </DialogActions>

            </Dialog>

        </>
    );

};